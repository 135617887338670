import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const TypeofVisitPop=({IsTypeofVisit,setIsTypeofVisit})=>{
    if(!IsTypeofVisit) return null
    return(<>
     <div
      className="fixed inset-0 flex items-center justify-center z-50"
      onClick={() => setIsTypeofVisit(false)}
      
      // Close the popup when the overlay is clicked
    > <div className="absolute inset-0  bg-black opacity-50"></div>
     <div
        className="relative z-10 max-w-xs min-w-[380px] p-4 rounded-sm bg-white shadow-custom flex flex-col items-start"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >

<div className="p-2 w-full flex justify-between items-center border-b-2">
<div className="h-6[font-family:'Inter-Medium',Helvetica] font-medium text-[#1d2951] text-lg tracking-[0] leading-6 whitespace-nowrap">
Type of Visit
      </div>

          {/* Cross Icon Button */}
          <IconButton
            edge="end"
            aria-label="close"
            onClick={() => setIsTypeofVisit(false)}
            style={{color:'#C7C7CB'}}
          >
            <CloseIcon />
          </IconButton>
        </div> 
        <div>
        <div className="w-[335px] h-[233px]">
      <div className=" p-3 mt-4 gap-5 flex flex-col justify-around  bg-white">
          <div className="flex flex-col ">
        <div className="flex justify-between">
      <div className="flex items-center gap-2 [font-family:'Inter-Regular',Helvetica] font-normal text-[#1d2951] text-[16px] tracking-[0] leading-6 whitespace-nowrap">
      Labs-In-House-Female
      </div>
   
      </div>
      </div>
      <div className="flex flex-col ">
        <div className="flex justify-between">
      <div className="flex items-center gap-2 [font-family:'Inter-Regular',Helvetica] font-normal text-[#1d2951] text-[16px] tracking-[0] leading-6 whitespace-nowrap">
      Follow-up
      </div>
      <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#1d2951] text-[13px] tracking-[0] leading-6 whitespace-nowrap">
        
      </div>
      </div>
      </div>
      <div className="flex flex-col ">
        <div className="flex justify-between">
      <div className="flex items-center gap-2 [font-family:'Inter-Regular',Helvetica] font-normal text-[#1d2951] text-[16px] tracking-[0] leading-6 whitespace-nowrap">
      Telehealth-Madhu
      </div>
    
      </div>
      </div>
      </div>
    </div>  
        </div>       
        </div>
        </div>
    
    </>)
}
export default TypeofVisitPop