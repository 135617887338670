import React from 'react';
import Grid from '@mui/material/Grid';
import HeaderCalender from 'pages/Landing/patientcharts/tabs/HeaderCalender';

const TabsSwitchNav = ({ onTabClick, activeTab, handleView, view ,a }) => {
  const Button = ({ text, position, isActive, onClick }) => (
    <div
      onClick={() => onClick(position)} // Pass position instead of index
      className={`cursor-pointer mx-1 font-sans font-normal text-sm md:text-base tracking-tight text-center rounded-full shadow-md flex items-center justify-center transition-colors duration-300 ease-in-out ${
        isActive ? 'bg-[#50869A] text-white' : 'bg-white text-[#1d2951]'
      } px-4 md:px-6 h-[28px] md:h-[32px] whitespace-nowrap`}
    >
      {text}
    </div>
  );

  const handleTabClick = (position) => {
    // Directly use the position
    onTabClick(position); // Call the prop function to notify parent
  };

  return (
    <Grid container alignItems="center" justifyContent="space-around" spacing={1} wrap="wrap">
      {/* Left section with buttons */}
      <Grid item>
        <Grid container spacing={1} wrap="nowrap">
          {['Active', 'No-Show', 'Telehealth', 'Checked Out'].map((item, index) => (
            <Grid item key={index}>
              <Button
                text={item}
                position={index + 1} // Pass the position (1-based)
                isActive={activeTab === (index + 1)} // Check if current tab is active
                onClick={handleTabClick} // Update active tab
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Right section with calendar and icons */}
      <HeaderCalender handleView={handleView} view={view}  a={a}/>
    </Grid>
  );
};

export default TabsSwitchNav;
