import { CardView } from "./CardView";
import FollowUpCard from "./FollowUpCard";
import { DropDownFrame} from "./Frame";
import PatientHeader from "./PatientHeader";
import { TableView } from "./TableView";
import PatientsIcon from '../../../../../assets/patientCharts/mdi_needle.svg';
import LabsIcon from '../../../../../assets/patientCharts/akar-icons_dashboard2.svg';
import ProcedureIcon from '../../../../../assets/patientCharts/pulseicon.svg'
import { useState } from "react";
const SummaryTabWrap=()=>{
   const [view,setView]=useState(0)
   const handletoggle=(value)=>{
      console.log(value)
      setView(value)
    }
    return (<>
       <div className="p-8 pt-0">
       <DropDownFrame/>
       <PatientHeader viewStatus={view} handleView={handletoggle} />
       {view?( <div className="grid">
       <TableView/>
       </div> ):(<div className="grid grid-cols-2 gap-4">
          <CardView icon={LabsIcon} title="LABS" />
          <FollowUpCard />
          <CardView icon={PatientsIcon} title="INJECTIONS" />
          <CardView icon={ProcedureIcon} title="PROCEDURES" />
        </div>)}
      
       </div>     
      </>
      )
}
export default SummaryTabWrap;