// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slicer/userSlicer';

const store = configureStore({
  reducer: {
    user: userReducer
  }
});

export default store;
