import { useState, useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';

const DetailsNavbar = ({ refresh, dashboarddata, setDashboardData, filteredData, setFilteredData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [originalData, setOriginalData] = useState(dashboarddata); // State to hold the original data

  const username = localStorage.getItem('user');
  const today = new Date();
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-US', options);

  // Use useEffect to update originalData when dashboarddata changes
  useEffect(() => {
    setOriginalData(dashboarddata);
  }, [dashboarddata]);

  const handleSearch = (event) => {
    const value = event.target.value.trim();
    setSearchTerm(value);

    if (value === '') {
      // Reset to show all data
      setFilteredData([]);
      setShowSuggestions(false);
      setDashboardData(originalData); // Show all data when search is cleared
      return;
    }

    // Improved filtering logic for full names and patient ID
    const results = originalData.filter((item) => {
      const fullName = `${item.firstname.toLowerCase()} ${item.lastname.toLowerCase()}`;
      const searchValue = value.toLowerCase();

      // Convert id and patientid to strings for comparison
      const patientId = (item.patientid || '').toString(); // Ensure patientid is a string

      // Check for exact matches or substantial matches
      return (
        fullName.startsWith(searchValue) || // Full names starting with the search term
        fullName.includes(` ${searchValue}`) || // Full names having the search term after a space
        patientId.includes(searchValue) // Match on patientid containing search term
      );
    });

    setFilteredData(results);
    setShowSuggestions(true);
    setDashboardData(results);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(`${suggestion.firstname} ${suggestion.lastname}`);
    setShowSuggestions(false);
    setDashboardData([suggestion]);
  };

  return (
    <div className="w-full flex justify-between items-center relative">
      <div>
        <div className="font-bold text-neutral-700 text-xl leading-normal whitespace-nowrap">
          Hello, {username}
        </div>
        <div className="font-normal text-neutral-700 text-md leading-normal whitespace-nowrap flex items-center">
          <CalendarMonthIcon /> <span className="ml-2">{formattedDate}</span>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <div onClick={refresh} style={{ cursor: 'pointer' }}>
          <CachedIcon />
        </div>
        <div className="flex-1 min-w-[250px] max-w-[388px] relative">
          <div className="w-full h-8 bg-[#f5f6fa] rounded-full border border-neutral-300 flex items-center px-3">
            <SearchIcon className="text-neutral-500" />
            <input
              type="text"
              placeholder="Search..."
              className="w-full text-sm h-full bg-transparent border-none outline-none pl-2 text-neutral-700"
              value={searchTerm}
              onChange={handleSearch}
              onFocus={() => setShowSuggestions(true)}
            />
          </div>
          {showSuggestions && filteredData.length > 0 && (
            <div className="absolute z-10 w-full bg-white border border-neutral-300 rounded mt-1 max-h-60 overflow-y-auto">
              {filteredData.map((item) => (
                <div
                  key={item.id}
                  className="p-2 cursor-pointer hover:bg-neutral-100 text-sm"
                  onClick={() => handleSuggestionClick(item)}
                >
                  {item.firstname} {item.lastname}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsNavbar;
