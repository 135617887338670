const RPMdetails = {
    labels: ["Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Systolic",
        color: "error",
        data: [5,7,9,10],
      },
      {
        label: "Diastolic",
        color: "dark",
        data: [3, 4, 6,8],
      },
      {
        label: "Pulse",
        color: "success",
        data: [1, 3, 5, 7, ],
      },
    ],
  };
   
  export default RPMdetails;