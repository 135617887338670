import React, { useState } from 'react'
import TabsComponent from '../tabswitches'
import filterIcon from '../../../../../assets/patientCharts/filtericon.png';
import refreshicon from '../../../../../assets/patientCharts/ic-replay-24px.svg'
import LabsFilter from 'defaultLanding/overlays/LabsFilter';

const LabsHeader = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [LabsFilterPop,setLabsFilterPop]=useState(false)
    const tabs = [
      { label: "2024", width: 44 },
      { label: "2023", width: 44 },
      { label: "2022", width: 64 },
    ];

    return (
        <div className='flex justify-between items-center'>
            <div className='w-1/4'>
                <TabsComponent tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
            </div>
            <div className='w-1/3 flex items-start justify-center space-x-4 '>
    <div onClick={()=>setLabsFilterPop(true)}>
        <img src={filterIcon}  alt='Filter Icon' className='w-4 cursor-pointer'/>
        {LabsFilterPop&&<LabsFilter LabsFilterPop={LabsFilterPop} setLabsFilterPop={setLabsFilterPop} />}
    </div>
    <div className='flex items-start space-x-2'>
        <img src={refreshicon} alt='Refresh Icon'/>
        <div style={{fontSize:'14px'}} className='items-start'>REFRESH</div>
    </div>
</div>

        </div>
    );
}

export default LabsHeader;
