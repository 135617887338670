import PageLayout from "examples/LayoutContainers/PageLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginScreenImage from '../../assets/images/LoginScreen.png';
import BrianyCareLogo from '../../assets/images/BrainyCare-Logo.jpg';
import api from "api/api";
import axios from "axios";
export const LogInScreen = () => {
  const [username, setUsername] = useState("admin@chim.com");
  const [password, setPassword] = useState("Chim@123");
  const [error, setError] = useState("");
  const navigate=useNavigate()
  
  const params = new URLSearchParams();
 
  const handleLogin = async () => {
    params.append('username', username);
    params.append('password', password);
    params.append('client_id', '9928');    
    try {
      const tokenresp = await api.post('/auth/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    
      if (tokenresp.status === 200) {
        console.log(tokenresp.data)
        const { auth, access_token, data,id } = tokenresp;
        
        if(access_token){
          setError('')
          localStorage.setItem('auth', access_token);
          localStorage.setItem('user',data[0]?.username)
          navigate('/dashboards/default')

        }else{
          setError("Failed to authenticates");

        } 
       
      } else {
        setError("Failed to authenticate");
        console.error('Failed to authenticate:', tokenresp.statusText);
      }
    } catch (error) {
      // Handle errors such as network issues or invalid responses
      console.error('An error occurred during authentication:', error);
      setError("Something went wrong");
    }

  }

  return (
    <PageLayout>
      <div className="bg-[#1d2951] flex justify-center items-center w-full h-[100vh]">
        <div className="bg-white rounded-3xl p-10 flex flex-row justify-between w-[800px] shadow-lg">
          <div className="w-1/2">
            <div className="flex flex-col gap-3 p-2">
              <div className="flex">
                <div>
                  <img
                    src={BrianyCareLogo}
                    alt="C-HIM Logo"
                    className="w-16 mb-4"
                  />
                </div>
                <div className="flex flex-col ml-1">
                  <div className="text-[32px] font-black text-[#1e90ff] leading-none" style={{ fontFamily: 'Montserrat, sans-serif' }}>
                    C-HIM
                  </div>
                  <div className="text-xs font-bold ml-1 mt-3">
                    Center for Hypertension & Internal Medicine
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="username" className="text-sm">
                  User Name
                </label>
                <input
                  id="username"
                  type="text"
                  placeholder="User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 text-sm"
                />
                <label htmlFor="password" className="text-sm mt-4">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 text-sm"
                />
              </div>
              {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
              <u href="#" className="text-orange-500 text-sm mt-2">
                Forget your password?
              </u>
              <button
                onClick={handleLogin}
                className="bg-[#E17954] text-white p-3 text-sm rounded-3xl mt-4"
              >
                Log in
              </button>
            </div>
          </div>
          <div className="w-1/2 flex justify-end items-center">
            <img
              src={LoginScreenImage}
              alt="Doctor and Patient Illustration"
              className="w-[300px] h-auto"
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
