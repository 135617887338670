import React from 'react';

const UnitSelector = ({ text, width, isSelected, onClick }) => {
  return (
    <div
      className={`border rounded-md h-[25px] items-center flex justify-center cursor-pointer 
        ${isSelected ? 'bg-[#E17954] text-white' : ''}`}
      style={{ width }}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default UnitSelector;
