import React from 'react'
import Bedpatient from '../../../../../assets/patientCharts/fontisto_bed-patient.svg';
import RightTick from '../../../../../assets/patientCharts/grommet-icons_status-good.svg'
const FollowUpCard = () => {
  return (
    <div className="w-[393px] h-[180px] flex flex-col items-start justify-start p-6 bg-white rounded-[15px] shadow-md border-t-2 border-[#1d2951]">
    <div className='flex justify-between w-full'>
    <div className="flex items-center mb-2 mx-1.5">
      <img src={Bedpatient} alt="Labs Icon" className="mr-2" />
      <span className="text-sm text-[#1d2951] font-semibold ">VISIT</span>
    </div>
    <div>
    <img src={RightTick} alt="Labs Icon" className="mr-2" />  
    </div>
    </div>
  
    <div className="w-full flex justify-start align-start p-4">
            <div className="[font-family:'Inter-ExtraLight',Helvetica] text-[#1d2951]  tracking-[0] leading-[normal] whitespace-nowrap" style={{fontSize:'15px'}}>
                Follow-up Level 4
            </div>
    </div>
    <div className="w-full flex justify-start align-start p-1 mt-6">
            <div className="[font-family:'Inter-Light',Helvetica] font-light text-[#1d2951] text-base text-center tracking-[0] leading-[normal] whitespace-nowrap">
                Oco Montoya
            </div>
        </div>

  </div>
  )
}

export default FollowUpCard