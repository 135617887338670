import React, { useState } from 'react';
import blinkshare from '../../../../assets/patientCharts/blinkscribe 2.svg';
import recordbutton from '../../../../assets/patientCharts/recordbutton.png';
import redwave from '../../../../assets/patientCharts/redwave.png';
import messageIcon from '../../../../assets/patientCharts/mingcute_more-3-fill.svg'
import TypeofVisitPop from 'defaultLanding/overlays/TypeofVisit';
const SideFrame = ({audiotabchange,data}) => {
    const [percentage, setPercentage] = useState(20);
    const [activeButton, setActiveButton] = useState(3);
    const [TypeofVisit,setTypeofVisit]=useState(false)
const[isRecording,setIsRecording]=useState(0)
    const handleButtonClick = (index) => {
        setActiveButton(index);
    };
const handlerecording=(value)=>{
setIsRecording(value)
audiotabchange()
}
    const circleStyle = {
        background: `conic-gradient(#1D2951 ${percentage * 3.6}deg, #EDEDED ${percentage * 3.6}deg)`,
    };

    // Calculate the position of the small circle
    const angle = percentage * 3.6; // In degrees
    const radians = (angle - 90) * (Math.PI / 180); // Convert to radians
    const radius = 35; // Radius of the ring (half the width/height of the ring)
    const smallCircleOffset = 8; // Offset to position the small circle

    const smallCircleStyle = {
        position: 'absolute',
        top: `${60 + radius * Math.sin(radians) - smallCircleOffset}px`,
        left: `${60 + radius * Math.cos(radians) - smallCircleOffset}px`,
        width: '28px',
        height: '28px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    };

    return (
        <div className="flex flex-col items-center gap-4 p-4 w-full h-auto  bg-[#fafaf9] shadow-[0px_14px_42px_rgba(8,15,52,0.06)] ">
            {/* <button className="flex justify-center  items-center gap-[8px] text-xs w-[55px] h-[20px] p-[2px_16px] rounded-[18px] bg-[rgba(226,165,165,0.65)] text-[#EB5757] self-start">
                Close
            </button> */}

            <div className="relative flex justify-center items-center w-[90px] h-[90px] mt-1 mb-0">
                <div className="relative flex justify-center items-center w-[90px] h-[90px] rounded-full" style={circleStyle}>
                    <div className="flex justify-center items-center w-[75px] h-[75px] bg-[#FAFAF9] rounded-full">
                        <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#C4C4C4] rounded-full">
                        </div>
                    </div>
                    <div style={smallCircleStyle} className='flex justify-center items-center'>
                    <div className=' font-inter text-[8px] text-xs text-[#000000]'
                    style={{fontSize:"8px",fontWeight:"700"}}>{percentage}%</div>
                    </div>
                    </div>
            </div>

            <div className="flex flex-col items-center mb-0">
                <div className="font-medium text-[#202020] text-sm text-center font-bold">
                {(data?.firstname+" "+data.lastname)||"-"}
                </div>
                <div className="font-medium text-[#7e7e7e] text-[13px] text-center ">
            #{data?.appointmentid||'-'}
                </div>
                <div className="font-medium text-[#7e7e7e] text-[13px] text-center ">
                    {data?.dob||"-"}
                </div>
            </div>

            <div className="flex-grow flex flex-col items-center border-t-2">
    <div className="w-full flex justify-center items-center p-0 mt-2 mb-4">
        <img src={blinkshare} alt="" className="max-w-full max-h-[150px] object-contain" />
    </div>
                <div className="w-full flex justify-center items-center">
                   {isRecording?<div className='flex'>
                    <div className="w-12 h-12">
      <div 
      onClick={()=>handlerecording(0)}
      className=" w-10 h-10  bg-red-500 rounded-3xl flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
<path d="M20.3125 3.25H16.25C15.819 3.25 15.4057 3.4212 15.101 3.72595C14.7962 4.0307 14.625 4.44402 14.625 4.875V21.125C14.625 21.556 14.7962 21.9693 15.101 22.274C15.4057 22.5788 15.819 22.75 16.25 22.75H20.3125C20.7435 22.75 21.1568 22.5788 21.4615 22.274C21.7663 21.9693 21.9375 21.556 21.9375 21.125V4.875C21.9375 4.44402 21.7663 4.0307 21.4615 3.72595C21.1568 3.4212 20.7435 3.25 20.3125 3.25ZM20.3125 21.125H16.25V4.875H20.3125V21.125ZM9.75 3.25H5.6875C5.25652 3.25 4.8432 3.4212 4.53845 3.72595C4.2337 4.0307 4.0625 4.44402 4.0625 4.875V21.125C4.0625 21.556 4.2337 21.9693 4.53845 22.274C4.8432 22.5788 5.25652 22.75 5.6875 22.75H9.75C10.181 22.75 10.5943 22.5788 10.899 22.274C11.2038 21.9693 11.375 21.556 11.375 21.125V4.875C11.375 4.44402 11.2038 4.0307 10.899 3.72595C10.5943 3.4212 10.181 3.25 9.75 3.25ZM9.75 21.125H5.6875V4.875H9.75V21.125Z" fill="white"/>
</svg>
      </div>
    </div>
                    <div className='flex flex-col justify-center items-center '>
                    <img
                        className="w-[38px] h-[18px]"
                        alt="Record wave"                        
                        src={redwave}
                    />  
<div className="[font-family:'Inter-Regular',Helvetica] font-normal text-[#1c2a3a] text-[10px] text-center tracking-[0] whitespace-nowrap">
        00:00
      </div>
                    </div>
                    
                    </div>: <img
                        className="w-[38px] h-[38px]"
                        alt="Record button action"
                        onClick={()=>setIsRecording(true)}
                        src={recordbutton}
                    />}
                </div>

                <div className="flex flex-col items-start w-full mt-1">
                    <div className="font-medium text-[#7e7e7e] text-[13px]">
                        Healthcare
                    </div>
                    <div className="font-medium break text-left text-[#202020] text-sm mt-1 text-[17.5px]">
                    {data?.insurancename||"-"}
                    </div>
                </div>
                <div className="flex flex-col items-start w-full mt-2">
                    <div className="font-medium text-[#7e7e7e] text-[12px]">
                        Provider
                    </div>
                    <div className="font-medium text-[#202020] text-sm mt-1">
                    {data?.linked_provider||"-"}
                    </div>
                </div>
                <div className="flex flex-col items-start w-full mt-2">
                    <div className="font-medium text-[#7e7e7e] text-[12px]">
                        Type of Visit
                    </div>
                    <div className="font-medium text-[#202020] text-sm mt-1 flex justify-between w-full">
                        <div>Labs-In-House-Female</div> <div className='cursor-pointer' onClick={()=>{setTypeofVisit(true)}}><img src={messageIcon} alt='/messagepop'/></div>
                        {TypeofVisit&& <TypeofVisitPop IsTypeofVisit={TypeofVisit} setIsTypeofVisit={setTypeofVisit} />} 
                        
                    </div>
                </div>
                <div className="flex flex-col items-start w-full mt-2">
                    <div className="font-medium text-[#7e7e7e] text-[13px]">
                        Level Of Visit - 99213
                    </div>
                    <div className="flex items-center gap-1.5 px-0 py-1">
                        {[1, 2, 3, 4, 5].map((number) => (
                            <button
                                key={number}
                                onClick={() => handleButtonClick(number)}
                                className={`w-9 h-[32px] flex items-center justify-center rounded-lg shadow-[0px_0px_4px_2px_#0000001a] ${
                                    activeButton === number ? 'bg-[#e17954] text-white' : 'bg-white text-[#1d2951]'
                                }`}
                                style={{ left: `${(number - 1) * 30}px` }}
                            >
                                <div className="font-bold text-[10px] text-center">
                                    {number}
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
 <div className="flex w-[100px] h-[40px] mt-7  items-center justify-center  bg-[#e17954] rounded-lg">
<button
  className="[font-family:'Tahoma',Helvetica]  text-white text-base tracking-[0] leading-7 whitespace-nowrap bg-transparent border-none cursor-pointer"
>
  Order
</button>

            </div>
            </div>

 
        </div>
    );
};

export default SideFrame;
