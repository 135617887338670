import React from 'react';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import editIcon from '../../src/assets/patientCharts/Group.svg'
export default function PcardPopup({ setPcard,data, isPcard }) {
  if (!isPcard) return null;

  const percentage = 75; 
  const circleStyle = {
    background: `conic-gradient(#50869A ${percentage * 3.6}deg, #EDEDED ${percentage * 3.6}deg)`,
  };

  const angle = percentage * 3.6;
  const radians = (angle - 90) * (Math.PI / 180);
  const radius = 35;
  const smallCircleOffset = 8;

  const smallCircleStyle = {
    position: 'absolute',
    top: `${50 + radius * Math.sin(radians) - smallCircleOffset}px`,
    left: `${50 + radius * Math.cos(radians) - smallCircleOffset}px`,
    width: '22px',
    height: '22px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const formattedDate = new Date(data?.dob || "").toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50"
      onClick={() => setPcard(false)}
      
      // Close the popup when the overlay is clicked
    >
      {/* Overlay */}
      <div className="absolute inset-0  bg-black opacity-50"></div>

      <div
        className="relative z-10 max-w-xs min-w-[380px] p-4 rounded-sm bg-white shadow-custom flex flex-col items-start"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >
        <div className="p-2 w-full flex justify-between items-center border-b-2">
          <div className="h-6 [font-family:'Tahoma',Helvetica] font-medium text-[#1d2951] text-[15px] font-semibold tracking-[0] leading-6 whitespace-nowrap">
            pCard
          </div>

          {/* Cross Icon Button */}
          <IconButton
            edge="end"
            aria-label="close"
            onClick={() => setPcard(false)}
            style={{color:'#C7C7CB'}}
          >
            <CloseIcon />
          </IconButton>
        </div>        
        <div className="flex w-full  mt-10">
          <div className="flex flex-col items-center gap-1 w-[50%]">
            <div className="relative mt-2 flex justify-center w-[65px] h-[65px] mr-4">
              <div
                className="relative flex justify-center items-center w-[65px] h-[65px] rounded-full"
                style={circleStyle}
              >
                <div className="flex justify-center items-center w-[55px] h-[55px] bg-[#FAFAF9] rounded-full">
                  <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#C4C4C4] rounded-full"></div>
                </div>
                <div style={smallCircleStyle} className="flex justify-center items-center">
                  <div className="text-[8px] font-bold text-black">{percentage}%</div>
                </div>
              </div>
            </div>
            <div className="w-[219px] text-center [font-family:'Inter-Medium',Helvetica] font-medium text-[#202020] text-sm ">
            {(data?.firstname+" "+data.lastname)||"-"}
            </div>
            <div className="w-[147px] text-center [font-family:'Inter-Light',Helvetica] font-light text-[#989898] text-sm ">
            #{data?.appointmentid||'-'}
            </div>
          </div>

          <div className="flex flex-col gap-2 w-[50%] mt-5">
            <div className="flex gap-2 items-center">
            <CalendarMonthIcon/>  
            <div className=" w-[58px] [font-family:'Roboto-Regular',Helvetica] font-normal text-[#1d2951] text-sm tracking-[0] leading-[normal]">
  last Visit
      </div>
      <div className="h-4  [font-family:'Inter-Regular',Helvetica] font-normal text-[#b1b1b1] text-sm tracking-[0] leading-4 whitespace-nowrap">
        06-17-2024
      </div>

            </div>
            <div className="flex w-full gap-2 items-center">
            <CalendarMonthIcon/>   
        <div className=" w-[60px] [font-family:'Roboto-Regular',Helvetica] font-normal text-[#1d2951] text-sm tracking-[0] leading-[normal]">
 Next Visit
      </div>
      <div className="h-4  [font-family:'Inter-Regular',Helvetica] font-normal text-[#b1b1b1] text-sm tracking-[0] leading-4 whitespace-nowrap">
        06-17-2024
      </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col p-2 w-full'>
      <div  className='flex  mt-4 flex-col gap-1 border-b w-full' >
       <div className="   [font-family:'Lexend-Light',Helvetica] font-light text-[#a1a0a7] text-sm tracking-[0] leading-[22px]">
        Date of Birth
      </div>
      <div className="  [font-family:'Lexend-Light',Helvetica] text-sm font-light text-[#16141b] text-base tracking-[0] leading-6">
      {formattedDate || "-"}
      </div>
</div>
<div  className='flex  mt-4 flex-col gap-1 border-b' >
   <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] font-light text-[#a1a0a7] text-sm tracking-[0] leading-[22px]">
   Mobile Number
      </div>
      <div className='flex justify-between items-start'>
      <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] text-sm font-light text-[#16141b] text-base tracking-[0] leading-6">
       {data.phone||"xxxx-xxxx-xx"}
      </div>
       <img src={editIcon} alt='/Edit' style={{width:'20px'}}/>
      </div>
    
</div>
<div  className='flex  mt-4 flex-col gap-1 border-b' >
   <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] font-light text-[#a1a0a7] text-sm tracking-[0] leading-[22px]">
   Email Address
      </div>
      <div className='flex justify-between items-start'>
      <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] text-sm font-light text-[#16141b] text-base tracking-[0] leading-6">
      {data.email||'-'}
      </div>
      <img src={editIcon} alt='/Edit' style={{width:'20px'}} />
      </div>
    
</div><div  className='flex  mt-4 flex-col gap-1 border-b' >
   <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] font-light text-[#a1a0a7] text-sm tracking-[0] leading-[22px]">
   Address
      </div>
      <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] text-sm font-light text-[#16141b] text-base tracking-[0] leading-6">
      2464 Royal Ln. Mesa, Dallas
      </div>
</div>
<div  className='flex  mt-4 flex-col gap-1 border-b' >
   <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] font-light text-[#a1a0a7] text-sm tracking-[0] leading-[22px]">
   Insurance
      </div>
      <div className="w-[350px]  [font-family:'Lexend-Light',Helvetica] text-sm font-light text-[#16141b] text-base tracking-[0] leading-6 whitespace-nowrap overflow-hidden text-ellipsis">
      {data?.insurancename||"-"}
      </div>
</div>
        </div>
        <div className='flex items-center w-full justify-center'>
        <div      
      className={`mt-5 cursor-pointer mx-3 font-sans font-normal text-xs md:text-[14px] tracking-tight text-center rounded-full shadow-md flex items-center justify-center transition-colors duration-300 ease-in-out 
    bg-[#50869A] text-white px-6 md:px-10 h-[28px] md:h-[32px] whitespace-nowrap`}
    >
      Update
    </div>
      </div>
      </div>
      
     
    </div>
  );
}
