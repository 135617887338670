import React, { useState } from "react";
import { NavFrame } from "./frames/navframe";
import SideFrame from "./frames/sideframe";
import SummaryTabWrap from "./tabs/summary/summarytabwrap";
import LabsWrap from "./tabs/labs/labsWrap";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Procedures from "./tabs/procedure/procedure";
import PriorVisit from "./tabs/priorvisits/priorvisit";
import Vitals from "./tabs/vitals/vitals";
import RPM from "./tabs/rpm/rpm";

const PateintsChartsWrapper = ({data}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const[internalnav,setInternaNav]=useState(0)
const audiotabchange=()=>{
setInternaNav(1)
setCurrentTab(5)

}
  const renderContent = () => {
    switch (currentTab) {
      case 0:
        return <SummaryTabWrap />;
      case 1:
        return <LabsWrap />;
      case 2:
        return <Procedures />;
        case 3:
          return <Vitals />;
          case 4:
            return <RPM/>
        case 5:
          return <PriorVisit internavtab={internalnav} />;
      default:
        return null;
    }
  };

  return (
    <PageLayout>
      <div className="flex w-full h-screen">
        <div className="w-[20%]">
          <SideFrame data={data} audiotabchange={audiotabchange}/>
        </div>
        <div className="flex-grow">
          <NavFrame
            activeTab={currentTab}
            onTabClick={(index) => setCurrentTab(index)}
          />
          <div className="p-6"> 
            {renderContent()}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PateintsChartsWrapper;
