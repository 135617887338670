import React, { useState } from 'react';
import brand from 'assets/images/BrainyCare-Logo.jpg';
import { Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { navbarIconButton,}from '../examples/Navbars/DashboardNavbar/styles'
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { setOpenConfigurator } from 'context';
import { useSoftUIController } from 'context';
const TopHeaderBar = () => {
  const [controller, dispatch] = useSoftUIController();
  const navigate=useNavigate()
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);
    const [profileMenu,setProfileMenu]=useState(false)
    const handlelogout=()=>{
      localStorage.removeItem('auth');
    navigate('/brainycare/sign-in/')
    }
    const handleProfileMenu = (event) => {
        if (profileMenu) {
          setProfileMenu(null);
        } else {
          setProfileMenu(event.currentTarget);
        }
      };
    const ProfileMenuPopUp=()=>(
        <Menu
        anchorEl={profileMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(profileMenu)}
        onClose={() => setProfileMenu(null)}
        sx={{ mt: 2 }}
      >
        <MenuItem onClick={handleProfileMenu}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText 
            primary={
              <Typography variant="body2">View Profile</Typography>
            }
          />
        </MenuItem>
        <Link to="/barinycare/lock"> 
        <MenuItem onClick={handleProfileMenu}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText 
            primary={
              <Typography variant="body2">Lock</Typography>
            }
          />
        </MenuItem></Link>
        
        <MenuItem onClick={handlelogout}>
    
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText 
            primary={
              <Typography variant="body2">Logout</Typography>
            }
          />
        
        </MenuItem>  
      </Menu>
    );
  return (
    <div className="w-full h-16 sticky z-10 ">
      <div className="fixed w-full h-16 top-0 left-0 bg-[#1d2951] rounded-b-lg flex items-center justify-between px-4">
        <img src={brand} alt="Brand Logo" className="h-8" />
        <div className="flex items-center space-x-6">
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 38 19"
              fill="none"
              className="w-5 h-5"
            >
              <path
                d="M23.1053 1H1M1 8.36842H23.1053M1 14.8158H18.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M37.566 5.94312C37.7503 6.15059 37.8493 6.42712 37.8412 6.71188C37.8331 6.99665 37.7185 7.26635 37.5227 7.46166L26.7975 18.1688C26.5789 18.3869 26.2884 18.5057 25.9881 18.4998C25.6878 18.4939 25.4016 18.3638 25.1908 18.1373L21.5661 14.2407C21.4702 14.1408 21.3939 14.0216 21.3418 13.8902C21.2897 13.7588 21.2628 13.6177 21.2627 13.4752C21.2626 13.3326 21.2892 13.1915 21.341 13.0601C21.3928 12.9287 21.4694 12.8097 21.5655 12.7101C21.6615 12.6106 21.7753 12.5327 21.9004 12.481C22.0254 12.4292 22.1591 12.4048 22.2934 12.4094C22.4277 12.414 22.5599 12.4473 22.6824 12.5071C22.8049 12.5669 22.9157 12.6525 23.0083 12.7595L25.9058 15.8388L36.0005 5.96172C36.1961 5.76613 36.4661 5.65114 36.7512 5.64256C37.0363 5.634 37.3123 5.73247 37.5197 5.91683L37.566 5.94312Z"
                fill="white"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="absolute -top-3 -right-3 flex items-center justify-center w-4 h-4 bg-red-500 rounded-full">
              <span className="text-xs text-white">18</span>
            </div>
          </div>
          <div className="relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 29" fill="none">
  <g clip-path="url(#clip0_822_8532)">
    <path d="M8.51886 17.7876V8.87506H2.93754C1.32189 8.87506 0 10.1688 0 11.7501V20.3751C0 21.9563 1.32189 23.25 2.93754 23.25H4.40631V27.5625L8.81262 23.25H16.1565C17.7721 23.25 19.094 21.9563 19.094 20.3751V17.7588C18.9974 17.7785 18.899 17.7886 18.8003 17.789H8.51886V17.7876ZM26.4379 1.68756H13.2189C11.6033 1.68756 10.2814 2.98131 10.2814 4.56256V16.0626H20.5628L24.9691 20.3751V16.0626H26.4379C28.0535 16.0626 29.3754 14.7702 29.3754 13.1876V4.56256C29.3754 2.98131 28.0535 1.68756 26.4379 1.68756Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_822_8532">
      <rect width="29.3754" height="28.75" fill="white" transform="translate(0 0.250061)"/>
    </clipPath>
  </defs>
</svg>
            <div className="absolute -top-3 -right-3 flex items-center justify-center w-4 h-4 bg-red-500 rounded-full">
              <span className="text-xs text-white">5</span>
            </div>
          </div>
          <IconButton
                size="medium"
                color="inherit"
             
                onClick={handleConfiguratorOpen}
              >
                <Icon    style={{color:"white"}}>settings</Icon>
              </IconButton>
          <div onClick={handleProfileMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 33" fill="none">
  <g clip-path="url(#clip0_787_7144)">
    <path d="M16.2648 15.8962C18.4486 15.8962 20.3397 15.1129 21.8848 13.5676C23.4299 12.0225 24.2132 10.1319 24.2132 7.94784C24.2132 5.7645 23.4299 3.87372 21.8845 2.32811C20.3392 0.783253 18.4484 0 16.2648 0C14.0807 0 12.1901 0.783253 10.645 2.32836C9.09991 3.87347 8.31641 5.76425 8.31641 7.94784C8.31641 10.1319 9.09991 12.0227 10.6453 13.5678C12.1906 15.1127 14.0814 15.8962 16.2648 15.8962Z" fill="white"/>
    <path d="M30.1724 25.3753C30.1278 24.7323 30.0377 24.0308 29.905 23.2901C29.771 22.5439 29.5986 21.8384 29.3921 21.1937C29.1789 20.5272 28.8888 19.8691 28.5303 19.2384C28.1582 18.5838 27.7211 18.0138 27.2307 17.5448C26.7178 17.0541 26.0899 16.6596 25.3638 16.3718C24.6402 16.0855 23.8383 15.9405 22.9806 15.9405C22.6437 15.9405 22.3179 16.0787 21.6887 16.4883C21.3015 16.7409 20.8486 17.0329 20.343 17.3559C19.9107 17.6314 19.3251 17.8894 18.6018 18.1231C17.8961 18.3514 17.1795 18.4673 16.4723 18.4673C15.7651 18.4673 15.0488 18.3514 14.3423 18.1231C13.6198 17.8897 13.0341 17.6316 12.6023 17.3562C12.1016 17.0362 11.6484 16.7441 11.2554 16.4881C10.627 16.0785 10.3009 15.9402 9.96405 15.9402C9.10602 15.9402 8.30438 16.0855 7.58105 16.372C6.85545 16.6593 6.22728 17.0538 5.71392 17.545C5.22372 18.0143 4.7864 18.5841 4.41479 19.2384C4.05652 19.8691 3.76648 20.527 3.55298 21.1939C3.34678 21.8387 3.17432 22.5439 3.04037 23.2901C2.90769 24.0298 2.81756 24.7315 2.773 25.376C2.72919 26.0075 2.70703 26.6628 2.70703 27.3247C2.70703 29.0473 3.25463 30.4419 4.33447 31.4704C5.40097 32.4852 6.81214 33.0001 8.52821 33.0001H24.4179C26.134 33.0001 27.5446 32.4855 28.6114 31.4704C29.6915 30.4426 30.2391 29.0478 30.2391 27.3245C30.2388 26.6596 30.2164 26.0037 30.1724 25.3753Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_787_7144">
      <rect width="33" height="33" fill="white"/>
    </clipPath>
  </defs>
</svg>
</div>
           
        </div>
        {ProfileMenuPopUp()}
      </div>
    </div>
  );
};

export default TopHeaderBar;
