import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs'; // Ensure dayjs is installed

const CustomDateCalendar = styled(DateCalendar)(({ theme }) => ({
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '15px', // Adjust the size as needed
  },
}));

export default function BasicDateCalendar({ onDateChange }) {
  const [selectedDate, setSelectedDate] = React.useState(dayjs());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateChange(date); // Call the parent callback function with the selected date
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <CustomDateCalendar
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
    </LocalizationProvider>
  );
}
