import React from "react";

// Button component
const Button = ({ text, isActive, onClick }) => (
  <div
    onClick={onClick}
    className={`cursor-pointer mx-2 font-sans font-normal text-base tracking-tight text-center rounded-full shadow-md flex items-center justify-center transition-colors duration-300 ease-in-out ${
      isActive ? 'bg-[#e17954] text-white' : 'bg-white text-[#1d2951]'
    } px-6 h-[42px]`} // Fixed width and height
  >
    {text}
  </div>
);

export const NavFrame = ({ onTabClick, activeTab }) => {
  return (
    <div className="bg-transparent flex justify-start w-full pt-5 ">
      <div className="w-[100%] flex  ">
        <div
          className=" w-[full] h-[53px] bg-no-repeat bg-cover"
          style={{ backgroundImage: "url(/group-75.png)" }}
        >
          <div className="flex items-center justify-between px-1.5  ml-[30px]">
            {['Summary', 'Labs', 'Procedures', 'Vitals',  'RPM', 'Prior Visits'].map((item, index) => (
              <Button
                key={index}
                text={item}
                isActive={activeTab === index}
                onClick={() => onTabClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};