import { useState } from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import RPMdetails from "../../data/rpmgraphdeatils";

const RPM = () => {
  const [graphSwitch, setGraphSwitch] = useState({
    Systolic: false, // Initially set to false for not displaying Systolic
    Diastolic: false, // Initially set to false for not displaying Diastolic
    Pulse: true, // Initially set to true for displaying Pulse
  });

  const toggleGraph = (type) => {
    setGraphSwitch((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  // Filter datasets based on the graphSwitch status and limit data points to 10
  const filteredChartData = {
    ...RPMdetails,
    datasets: RPMdetails.datasets
      .filter((dataset) => graphSwitch[dataset.label])
      .map((dataset) => ({
        ...dataset,
        data: dataset.data.slice(0, 10), // Limit to 10 data points
      })),
  };

  return (
    <>
      <div className="flex flex-col justify-center">
        <div className="w-full mt-5 [font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#3a405b] text-xl tracking-[1.00px] leading-[17px] whitespace-nowrap">
          RPM
        </div>
        <div className="mt-3">
          <GradientLineChart chart={filteredChartData} />
        </div>
        <div className="flex justify-around items-center mt-5">
          <div className="flex gap-5">
            <div
              onClick={() => toggleGraph("Systolic")}
              className="text-center [font-family:'Inter-Regular',Helvetica] font-normal text-base tracking-[0] leading-5 whitespace-nowrap"
              style={{
                borderBottom: graphSwitch.Systolic
                  ? "3px solid #E17954"
                  : "none",
                paddingBottom: "5px",
                color: "#E17954",
                width: "33%",
                cursor: "pointer",
              }}
            >
              Systolic
            </div>
            <div
              onClick={() => toggleGraph("Diastolic")}
              className="text-center [font-family:'Inter-Regular',Helvetica] font-normal text-base tracking-[0] leading-5 whitespace-nowrap"
              style={{
                borderBottom: graphSwitch.Diastolic
                  ? "3px solid #1D2951"
                  : "none",
                paddingBottom: "5px",
                color: "#1D2951",
                width: "33%",
                cursor: "pointer",
              }}
            >
              Diastolic
            </div>
            <div
              onClick={() => toggleGraph("Pulse")}
              className="text-center [font-family:'Inter-Regular',Helvetica] font-normal text-base tracking-[0] leading-5 whitespace-nowrap"
              style={{
                borderBottom: graphSwitch.Pulse
                  ? "3px solid #50C569"
                  : "none",
                paddingBottom: "5px",
                color: "#50C569",
                width: "33%",
                cursor: "pointer",
              }}
            >
              Pulse
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RPM;
