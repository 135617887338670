import React, { useState } from 'react';
import { IconButton, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dropdowns from './Dropdowns';

const LabsFilter = ({ LabsFilterPop, setLabsFilterPop }) => {
    // State to manage the selected values
    const [panel, setPanel] = useState('');
    const [test, setTest] = useState('');
    const [range, setRange] = useState('');
    const [year, setYear] = useState('');

    if (!LabsFilterPop) return null;

    // Handlers for dropdown changes
    const handlePanelChange = (event) => setPanel(event.target.value);
    const handleTestChange = (event) => setTest(event.target.value);
    const handleRangeChange = (event) => setRange(event.target.value);
    const handleYearChange = (event) => setYear(event.target.value);

    const OptionName1='Options'
    const OptionName2='Laboratory test'
    const OptionName3='Ref range'
    const OptionName4='Year'

    return (
        <>
            <div
                className="fixed inset-0 flex items-center justify-center z-50"
                onClick={() => setLabsFilterPop(false)} // Close the popup when the overlay is clicked
            >
                <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay */}
                <div
                    className="relative z-10 max-w-xs min-w-[250px] p-4 rounded-sm bg-white shadow-custom flex flex-col items-start"
                    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                >
                    <div className="p-2 w-full flex justify-between items-center border-b-2">
                        <div className="h-6 [font-family:'Inter-Medium',Helvetica] font-medium text-[#1d2951] text-md tracking-[0] leading-6 whitespace-nowrap">
                            Filter
                        </div>

                        {/* Close Icon Button */}
                        <IconButton
                            edge="end"
                            aria-label="close"
                            onClick={() => setLabsFilterPop(false)}
                            style={{ color: '#C7C7CB' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div className="w-[250px] ">
                        <div className=" gap-2 flex flex-col justify-around bg-white mt-2">
                            {/* PANEL Dropdown with "standard" variant */}
                            <Dropdowns name={OptionName1}/>

                            {/* Laboratory test Dropdown with "filled" variant */}
                            <Dropdowns name={OptionName2}/>

                            {/* Ref range Dropdown with "standard" variant */}
                            <Dropdowns name={OptionName3}/>

                            {/* Year Dropdown with "filled" variant */}
                            <Dropdowns name={OptionName4}/>

                        </div>
                    </div>

                    {/* Apply Button */}
                    <div className="w-full flex justify-center mt-20">
                        <Button
                            onClick={() => {
                                // Handle apply action
                                setLabsFilterPop(false);
                            }}
                            style={{ color: 'black' }}
                        >
                            APPLY
                        </Button>
                    </div>

                    {/* Border below Apply Button */}
                    <div className="w-full border-t-2 border-gray-200 mb-2 mt-2"></div>
                </div>
            </div>
        </>
    );
}

export default LabsFilter;
