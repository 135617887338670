// src/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: 'bmadmin',
  password: 'Abc@123'
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsername(state, action) {
      state.username = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    clearUser(state) {
      state.username = '';
      state.password = '';
    }
  }
});

export const { setUsername, setPassword, clearUser } = userSlice.actions;

export default userSlice.reducer;
