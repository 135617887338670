import PateintsChartsWrapper from "pages/Landing/patientcharts/patientchartwrap";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
const ModalOverlay = ({ isOpen, onClose,modaldata }) => {
  if (!isOpen) return null;
  
  return (
    <div className="fixed  z-50 flex items-center justify-center bg-black bg-opacity-50 top-16">
      <div className="bg-white  shadow-lg w-full h-full">
        <button
          className="absolute top-2 right-4 text-black"
          onClick={onClose}
        >
          <CloseIcon style={{width:'34px',height:'34px',color:'#C7C7CB'}}/>
        </button>
        <PateintsChartsWrapper data={modaldata}/>
      </div>
    </div>
  );
};

export default ModalOverlay;
