import { useState } from "react";
import { ProcedureCardsGrid, ProcedureCardsList } from "./procedurecards";
import ProcedureHeader from './procedureHeader'
import TabsComponent from "../tabswitches";

const Procedures = () => {
  const [view, setView] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { label: "2024", width: 44 },
    { label: "2023", width: 44 },
    { label: "Archive", width: 64 },
  ];
  const handletoggle=(value)=>{
    console.log(value)
    setView(value)
  }
  return (
    <div className="flex flex-col justify-center">
      <ProcedureHeader viewStatus={view} handleView={handletoggle} />
      <TabsComponent tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
      {view ? (
        <div className="flex flex-col gap-4 overflow-auto max-h-screen p-4">
          <ProcedureCardsList />
          <ProcedureCardsList />
          <ProcedureCardsList />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
          <ProcedureCardsGrid />
          <ProcedureCardsGrid />
        </div>
      )}
    </div>
  );
};

export default Procedures;
