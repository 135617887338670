import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.prd.brainymed.com/ag',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth'); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem('auth');
    //  window.location.replace('/brainycare/sign-in/');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Flag to track if the refresh token request has already been attempted
let isRefreshing = false;

// Function to refresh the token
const refreshAuthToken = async () => {
  try {
    const accessToken = localStorage.getItem('auth');
    const response = await api.post(
      '/v1/9928/auth/refresh-token',
      { "access_token": accessToken },
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (response && response.status === 200) {
      localStorage.setItem('auth', response.refresh_token);
      return response.refresh_token;
    } else {
      localStorage.removeItem('auth');
      window.location.replace('/brainycare/sign-in/');
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    localStorage.removeItem('auth');
    window.location.replace('/brainycare/sign-in/');
    console.error("Failed to refresh token:", error);
    throw error;
  }
};

// Response interceptor to handle 401 errors and retry the request
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const newToken = await refreshAuthToken();
          if (newToken) {
            // Update the Authorization header with the new token
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            isRefreshing = false;
            // Retry the original request with the new token
            return api(originalRequest);
          }
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      } else {
        // If refresh token failed or is still in progress, remove the token
        localStorage.removeItem('auth');
     
        return Promise.reject(error);
      }
    }

    if (!originalRequest._retry) {
      localStorage.removeItem('auth');
    }

    return Promise.reject(error);
  }
);

export default api;
