import { useState } from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import VitalsLineChartData from "../../data/vitalsgraphdata";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DeviceThermostat, Favorite, MonitorHeart, Whatshot } from "@mui/icons-material";
import { weightbpmdata } from "../../data/vitalsgraphdata";
import { FormControl, MenuItem, Select } from "@mui/material";
import TabsComponent from "../tabswitches";
import Intake from "./Intake";

const Vitals = () => {
  const [mainTabValue, setMainTabValue] = useState(0); // State for main tabs (Vitals/Trends)
  const [graphSwitch, setGraphSwitch] = useState({
    Systolic: true,
    Diastolic: true,
    Pulse: true,
  });
  const [subTabValue, setSubTabValue] = useState(0); // State for sub-tabs (BP, Weight, etc.)
  const [selectedTab, setSelectedTab] = useState(0); // State for overall main tabs (Vitals and Intake)

  const tabs = [
    { label: "Intake", width: 50 },
    { label: "Trends", width: 50 },
  ];

  const toggleGraph = (type) => {
    setGraphSwitch((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const filteredChartData = {
    ...VitalsLineChartData,
    datasets: VitalsLineChartData.datasets.filter((dataset) =>
      graphSwitch[dataset.label]
    ),
  };

  return (
    <div className="flex flex-col justify-center h-auto border w-[90%] p-3 pr-6 pl-6 mb-16 border-[#DAE3F8] rounded-xl">
      {/* Main Tabs for Intake and Trends */}
      <div className="flex justify-between">
        <div>
          <TabsComponent tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
        </div>

        <div className="flex flex-col">
          <div className="flex items-end justify-end">
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select labelId="demo-select-small-label" id="demo-select-small" value={10} label="Time Period">
                <MenuItem value={10} selected>Monthly</MenuItem>
                <MenuItem value={20}>Weekly</MenuItem>
                <MenuItem value={30}>Day</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      {/* Intake Tab Content */}
      {selectedTab === 0 && (
        <div >
          <Intake/>
          {/* Include your form component for Intake */}
        </div>
      )}

      {/* Trends Tab Content */}
      {selectedTab === 1 && (
        <div className="mt-2">
          {/* Sub-Tabs under Trends */}
          <Tabs
            value={subTabValue}
            onChange={handleSubTabChange}
            sx={{
              background: "transparent",
              border: "1px solid #DAE3F8",
              padding: "5px",
            }}
          >
            <Tab
              label="BP"
              icon={<Favorite sx={{ color: subTabValue === 0 ? "#fff" : "#000" }} />}
              sx={{
                backgroundColor: subTabValue === 0 ? "#E17954" : "transparent",
                color: subTabValue === 0 ? "#fff" : "#000",
                padding: "8px",
              }}
            />
            <Tab
              label="WEIGHT"
              icon={<Whatshot sx={{ color: subTabValue === 1 ? "#fff" : "#000" }} />}
              sx={{
                backgroundColor: subTabValue === 1 ? "#E17954" : "transparent",
                color: subTabValue === 1 ? "#fff" : "#000",
                padding: "8px",
              }}
            />
            <Tab
              label="PULSE"
              icon={<MonitorHeart sx={{ color: subTabValue === 2 ? "#fff" : "#000" }} />}
              sx={{
                backgroundColor: subTabValue === 2 ? "#E17954" : "transparent",
                color: subTabValue === 2 ? "#fff" : "#000",
                padding: "8px",
              }}
            />
            <Tab
              label="O2 SATURATION"
              icon={<DeviceThermostat sx={{ color: subTabValue === 3 ? "#fff" : "#000" }} />}
              sx={{
                backgroundColor: subTabValue === 3 ? "#E17954" : "transparent",
                color: subTabValue === 3 ? "#fff" : "#000",
                padding: "8px",
              }}
            />
          </Tabs>

          {/* Chart Content */}
          <div style={{ height: "330px"  }}>
            {/* Adjust height here */}
            <GradientLineChart
              chart={subTabValue === 0 ? filteredChartData : weightbpmdata}
            />
          </div>

          {/* Toggle Graphs */}
          <div className="flex justify-around items-center ">
            {subTabValue === 0 && (
              <div className="flex gap-5">
                <div
                  onClick={() => toggleGraph("Systolic")}
                  className="text-center font-normal text-base leading-5 cursor-pointer"
                >
                  {graphSwitch.Systolic && (
                    <div
                      className="inline-block w-2 mr-2 h-2 rounded-full"
                      style={{ backgroundColor: "#E17954" }}
                    ></div>
                  )}
                  Systolic
                </div>
                <div
                  onClick={() => toggleGraph("Diastolic")}
                  className="text-center font-normal text-base leading-5 cursor-pointer"
                >
                  {graphSwitch.Diastolic && (
                    <div
                      className="inline-block w-2 mr-2 h-2 rounded-full"
                      style={{ backgroundColor: "#50C569" }}
                    ></div>
                  )}
                  Diastolic
                </div>
              </div>
            )}
            {subTabValue === 1 && (
              <div className="flex gap-10">
                <div
                  onClick={() => toggleGraph("Systolic")}
                  className="text-center font-normal text-base leading-5 cursor-pointer"
                  style={{
                    borderBottom: graphSwitch.Systolic ? "3px solid #E17954" : "none",
                    paddingBottom: "5px",
                    color: "#E17954",
                    width: "33%",
                  }}
                >
                  WEIGHT
                </div>
                <div
                  onClick={() => toggleGraph("Diastolic")}
                  className="text-center font-normal text-base leading-5 cursor-pointer"
                  style={{
                    borderBottom: graphSwitch.Diastolic ? "3px solid #1D2951" : "none",
                    paddingBottom: "5px",
                    color: "#1D2951",
                    width: "33%",
                  }}
                >
                  BMI
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Vitals;
