import React, { useState } from "react";
import ClockIcon from '../../assets/patientCharts/clock.svg';
import sethoscopeIcon from '../../assets/patientCharts/maki_doctor.svg';
import calenderIcon from '../../assets/patientCharts/uil_notes.svg';
import doorIcon from '../../assets/patientCharts/fluent-mdl2_door.svg'
import ModalOverlay from "defaultLanding/modal/modaloverlay";
const RoomGridCard = ({modalopen,setIsStatusNotesPopUp,setIsOrdersOverlay,pcardopen,data}) => {
  const percentage = 75; // Set your percentage value here

  // Styles for the circular progress bar and small circle with percentage text
  const circleStyle = {
    background: `conic-gradient(#50869A ${percentage * 3.6}deg, #EDEDED ${percentage * 3.6}deg)`,
  };

  const angle = percentage * 3.6;
  const radians = (angle - 90) * (Math.PI / 180);
  const radius = 35;
  const smallCircleOffset = 8;

  const smallCircleStyle = {
    position: 'absolute',
    top: `${50 + radius * Math.sin(radians) - smallCircleOffset}px`,
    left: `${50 + radius * Math.cos(radians) - smallCircleOffset}px`,
    width: '22px',
    height: '22px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const CardBox = () => {
    return (
      <div className="w-[220px] h-[150px]">
        <div className="w-[220px] h-[150px] bg-white rounded-[26px] shadow-[inset_0px_0px_8px_2px_#00000040] p-6">
          <div className="flex justify-between w-full  border-b-2">
            <span className="text-xs text-[#1d2951]">Labs</span>
            <span className="flex items-center">
              <span className="text-green-500 mr-1">●</span>
              <span className="text-xs text-black">$50</span>
            </span>
          </div>
          
          <div className="flex justify-between w-full border-b-2 items-center">
            <span className="text-xs text-[#1d2951]">Injections</span>
            <span className="flex items-center">
              <span className="text-red-500 mr-1">●</span>
              <span className="text-xs text-black">$200</span>
            </span>
          </div>
          <div className="flex justify-between w-full border-b-2 items-center ">
            <span className="text-xs text-[transparent]">Injections</span>
            <span className="flex items-center">
              <span className="text-transparent mr-1 ">●</span>
              <span className="text-xs text-black text-[transparent]">$200</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

 
  return (<>
    <div className="bg-white w-full max-w-[800px] rounded-3xl shadow-lg p-4 flex items-center justify-between">
      {/* Left Section with Circular Progress */}
      <div className="flex flex-col w-[50%] cursor-pointer ">
        <div className="flex">
        <div onClick={()=>pcardopen(data.id)}className="relative flex justify-center  w-[65px] h-[65px] mr-4">
          <div
            className="relative flex justify-center items-center w-[65px] h-[65px] rounded-full"
            style={circleStyle}
          >
            <div className="flex justify-center items-center w-[55px] h-[55px] bg-[#FAFAF9] rounded-full">
              <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#C4C4C4] rounded-full"></div>
            </div>
            <div style={smallCircleStyle} className="flex justify-center items-center">
              <div className="text-[8px] font-bold text-black">{percentage}%</div>
            </div>
          </div>
        </div>

        {/* Patient Information and Orders Button */}
        <div className="flex flex-col justify-between h-full w-full ">
          <div className="w-full">
            <h3 className="text-black  text-sm" style={{fontSize:'15px',fontWeight:450}}>{(data.firstname+" "+data.lastname)||"-"}</h3>
            <div className="relative flex gap-2 items-center ">
            <p className="text-[#989898] text-xs">#{data.appointmentid||'-'}</p>
            <img onClick={()=>setIsStatusNotesPopUp(true)}src={calenderIcon} alt="/Calender" style={{color:'#505050',width:'18px'}}/>
            <span className="absolute top-[-10px] left-[75px] text-red-500 text-sm ">●</span>
            </div>
            <div className="flex flex-col justify-between">
            <p className=" text-xs text-[#989898] ">{data.insurancename||"-"}</p>
            <div className="flex justify-end">
            <button onClick={()=>setIsOrdersOverlay(true)} className="bg-[#D97842] text-white  px-6 text-xs rounded-full self-start">
            Orders
           </button>
            </div>
         
            </div>
              
          </div>
         
        </div>
        </div>
        <div
      className="w-[full] border-[1px] border-dashed border-[#EFEDE9] mt-2 mb-1"
      style={{
        borderImage:
          "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%233333' stroke-width='4' stroke-dasharray='6%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\") 1 stretch",
      }}
    ></div>
        <div className="flex justify-around items-start h-full">
        <div className="flex items-center">
          <img src={ClockIcon} alt="Clock Icon" className="w-4 h-4 mr-1" />
          <span className="text-xs font-bold text-[#1d2951]">{data.start_time||"-"}</span>
        </div>
        <div className="flex items-start gap-3">
        <div className="flex items-center ">
          <img src={sethoscopeIcon} alt="Doctor Icon" className="w-4 h-4 mr-1" />
          <span className="text-xs text-[#1d2951]">{data.linked_provider||"-"}</span>
        </div>
        <div className="flex flex-col text-xs text-gray-400 text-left ">
          <span>Follow Up</span>
          <span>Labs</span>
          <span>Ultrasound</span>
        </div>
        </div>
       
      
      </div>
      </div>

      {/* Cost Section */}
      <div className="relative w-[50%] flex justify-center">
      <CardBox/>
      {data.room_no?<div onClick={()=>modalopen(data.id)}  className="flex flex-col justify-around items-center absolute bottom-[-16px] right-[-16px] bg-[#F6E2DB] text-[#D97842] p-2 h-[80px] w-[60px] rounded-tl-2xl  rounded-bl-md rounded-br-[23px]">
           <div >
            <img src={doorIcon} style={{width:'17px'}} alt="/DoorIcon"/>
           </div>
            <div className="text-[13px]">{data.room_no||"-"}</div>
          </div>:  <div onClick={()=>modalopen(data.id)} className="flex flex-col justify-around items-center absolute bottom-[-16px] right-[-16px] bg-[#F6E2DB] text-[#D97842] p-2 h-[80px] w-[60px] rounded-tl-2xl  rounded-bl-md rounded-br-[23px]" ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg></div>}
      </div>
  

      {/* Right Section with Time, Doctor, and Room Info */}

    </div>      </>
  );
};

export default RoomGridCard;
