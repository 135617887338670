import { useEffect, useState } from "react";
import PriorVisitHeader from "./proirvisitHeader";
import { PriorCardsGrid, PriorCardsList } from "./priorcards";
import { SummaryCardGrid, SummaryCardsList } from "./summarycard";
import { AudioCardGrid, Audiocards } from "./audiocards";

const PriorVisit = ({internavtab}) => {
  const [view, setView] = useState(0);
  const [selectedTab, setSelectedTab] = useState(internavtab);
  const tabs = [
    { label: "Summary" },
    { label: "Audio" },
    { label: "Progress Notes" },
    { label: "Special Tab" } 
  ];
  const Handletoggle=(value)=>{
    setView(value)
  }
  

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
      
        return (
          <div className="flex flex-col gap-4 overflow-auto max-h-screen p-4">
          {!view ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <SummaryCardGrid />
              <SummaryCardGrid />
              <SummaryCardGrid />
              <SummaryCardGrid />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
          <SummaryCardsList/>
          <SummaryCardsList/>
          <SummaryCardsList/>
          <SummaryCardsList/>
            </div>
          )}
        </div>
        );
      case 1:
      
        return (
          <div className="flex flex-col gap-4 overflow-auto max-h-screen p-4">
          {!view ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <AudioCardGrid/>
            <AudioCardGrid/>
            <AudioCardGrid/>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
         <Audiocards/>
         <Audiocards/>
         <Audiocards/>
  

            </div>
          )}
        </div>
        );
      case 2:
    
        return (
          <div className="flex flex-col gap-4 overflow-auto max-h-screen p-4">
          {!view ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <PriorCardsGrid />
              <PriorCardsGrid />
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <PriorCardsList />
              <PriorCardsList />
            </div>
          )}
        </div>
        );
  
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <PriorVisitHeader 
        viewStatus={view} 
        handleView={Handletoggle} 
        tabs={tabs} 
        selectedTab={selectedTab} 
        onSelectTab={setSelectedTab} 
      />
      {renderContent()}
    </div>
  );
};

export default PriorVisit;
