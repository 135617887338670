import React from "react";

export const TableView = () => {
  const data = [
    { label: "LABS", description: "Inhouse Labs - Female", orderedBy: "Dr. Madhu", status: "red" },
    { label: "VISIT", description: "Follow-up Level 4", orderedBy: "Oco Montoya", status: "green" },
    { label: "SCHEDULE - RTO", description: "1 Month", orderedBy: "pr2", status: "green" },
    { label: "INJECTIONS", description: "INJ LIPO/B12", orderedBy: "oal", status: "green" },
    { label: "NOTES", description: "Refill Walgreens pharmacy", orderedBy: "Guadulupe Tejada", status: "green" },
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg mt-2 w-full" style={{ fontFamily: '"Poppins-Regular", Helvetica' }}>
      <div className="grid grid-cols-4 text-center border-b">
        <div className="col-span-2"></div>
        <div className="py-2 font-bold" style={{ fontSize: '14px' }}>Ordered by</div>
        <div className="py-2 font-bold" style={{ fontSize: '14px' }}>Status</div>
      </div>
      <div className="grid gap-y-2 ">
        {data.map((item, index) => (
          <div key={index} className="grid grid-cols-4 border-b items-center text-center py-2">
            <div>
            <button className="w-[160px] py-1 border-[1.5px] border-[#1d2951] text-[10px] font-semibold text-[#1d2951] rounded-sm">
            {item.label}
            </button>
            </div>
            <div className="text-[#1d2951]" style={{ fontSize: '14px', fontFamily: '"Poppins-Regular", Helvetica' }} >
              {item.description}
            </div>
            <div className="text-[#1d2951]" style={{ fontSize: '14px', fontFamily: '"Poppins-Regular", Helvetica' }}>
              {item.orderedBy}
            </div>
            <div>
              <div className={`w-3 h-3 rounded-full mx-auto ${item.status === "red" ? "bg-[#ed6c47]" : "bg-green-500"}`} ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
