const VitalsLineChartData = {
    labels: ["Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Systolic",
        color: "error",
        data: [5,7,9,10],
      },
      {
        label: "Diastolic",
        color: "success",
        data: [3, 4, 6,8],
      },
     
    ],
  };
  export const weightbpmdata = {
    labels: ["Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "WEIGHT",
        color: "error",
        data: [3, 4, 6,8],
      },
      {
        label: "BMI",
        color: "dark",
        data: [5,7,9,10],
      },
     
    ],
  };
  
  export default VitalsLineChartData;