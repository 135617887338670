import LabsHeader from "./LabsHeader";

const LabsWrap = () => {
  const labTests = [
    { name: "CMP", selected: false },
    { name: "CBC", selected: true },
    { name: "TSH", selected: false },
    { name: "THYROID PANEL", selected: false },
    { name: "VIT D", selected: false },
    { name: "VIT B-12", selected: false },
  ];
  const testDetails = [
    { name: "GRA%", value: "35", range: "35.0-80.0", reference: "35", status:"notAvailable", referenceStatus: "completed" },
    { name: "GRAN", value: "1.4", range: "35.0-80.0", reference: "1.4", status: "pending", referenceStatus: "completed" },
    { name: "HEMATOCRIT", value: "123", range: "35.0-80.0", reference: "123", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "HEMAGLOBIN", value: "23", range: "35.0-80.0", reference: "23", status: "completed", referenceStatus: "notAvailable" },
    { name: "LYM", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "LYM%", value: "23", range: "35.0-80.0", reference: "23", status: "pending", referenceStatus: "notAvailable" },
    { name: "MCH", value: "23", range: "35.0-80.0", reference: "23", status: "completed", referenceStatus: "notAvailable" },
    { name: "MCHC", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "MCB", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "MID", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "MID%", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "MPV", value: "23", range: "35.0-80.0", reference: "23", status: "completed", referenceStatus: "notAvailable" },
    { name: "PLATELET COUNT", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "RBC", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "RDW", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },
    { name: "WBC", value: "23", range: "35.0-80.0", reference: "23", status: "notAvailable", referenceStatus: "notAvailable" },

  ];

  const statusColors = {
    completed: "#4caf50",  // Green for completed
    pending: "#e17954",    // Orange for pending
    notAvailable: "white", // Gray for not available
  };

  return (
    <div className="pl-12">
      <LabsHeader />
      <div className="flex w-[90%] flex-col items-start justify-start p-4 bg-white rounded-[15px] shadow-md border-t-2 border-[#1d2951]">
        <div className="flex text-sm justify-around w-full border-b">
          <div>Panel</div>
          <div>Lab Test</div>
          <div>08-08-2024</div>
          <div>27-08-2024</div>
          <div>Ref.range</div>
        </div>

        <div className="flex h-[400px] bg-white rounded-[17px] space-x-12 pt-2">
          <div className="flex flex-col gap-4 h-full align-center justify-center">
            {labTests.map((test, index) => (
              <button
                key={index}
                className={`w-36 h-[27px] rounded-[2.5px] border shadow-[0px_1px_2px_#0000002e,0px_1px_3px_#0000001a] ${
                  test.selected
                    ? "bg-[#50869a] border-[#004d40] text-white"
                    : "bg-white border-[#d1d5db] text-[#a9a9a9]"
                }`}
              >
                <div className="h-2.5 font-semibold text-[12px] text-center leading-[10px] whitespace-nowrap">
                  {test.name}
                </div>
              </button>
            ))}
          </div>

          <div >
            {testDetails.map((detail, index) => (
              <div key={index} className="w-[110px] flex items-center">
                <div className="font-normal text-black text-[13px] text-sm tracking-[0] leading-[23px]">
                  {detail.name}
                </div>
              </div>
            ))}
          </div>

          <div className="w-[110px] h-[391px]">
            {testDetails.map((detail, index) => (
              <div key={index} className="flex items-center">
                <div className="w-2.5 h-2.5 mr-2 rounded-full" style={{ backgroundColor: statusColors[detail.status] }} />
                <div className="font-normal text-black text-[14px] tracking-[0] leading-[23.8px]">
                  {detail.value}
                </div>
              </div>
            ))}
          </div>

          <div className="w-[128px] h-[412px]">
            {testDetails.map((detail, index) => (
              <div key={index} className="flex items-center">
                <div className="w-2.5 h-2.5 mr-2 rounded-full" style={{ backgroundColor: statusColors[detail.referenceStatus] }} />
                <div className="font-normal text-[#898c8f] text-[13px] tracking-[0] leading-[23.8px]">
                  {detail.reference}
                </div>
              </div>
            ))}
          </div>

          <div className="w-16 h-[391px]">
            {testDetails.map((detail, index) => (
              <div key={index} className="font-normal text-[#898c8f] text-[12px] tracking-[0] leading-[23.8px]">
                {detail.range}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabsWrap;
