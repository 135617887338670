import React from "react";
import makidoctor from '../../assets/patientCharts/maki_doctor.svg';
import clockicon from '../../assets/patientCharts/clock.svg'
import doorIcon from '../../assets/patientCharts/fluent-mdl2_door.svg'
import calenderIcon from '../../assets/patientCharts/uil_notes.svg';
const RoomCard = ({modalopen,setIsStatusNotesPopUp,setIsOrdersOverlay,data,pcardopen}) => {
  const percentage = 75; // Set your percentage value here

  // Styles for the circular progress bar and small circle with percentage text
  const circleStyle = {
    background: `conic-gradient(#50869A ${percentage * 3.6}deg, #EDEDED ${percentage * 3.6}deg)`,
  };

  const angle = percentage * 3.6;
  const radians = (angle - 90) * (Math.PI / 180);
  const radius = 35;
  const smallCircleOffset = 8;

  const smallCircleStyle = {
    position: 'absolute',
    top: `${50 + radius * Math.sin(radians) - smallCircleOffset}px`,
    left: `${50 + radius * Math.cos(radians) - smallCircleOffset}px`,
    width: '22px',
    height: '22px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="bg-white w-[260px] h-[auto] rounded-[13%] shadow-lg p-6 flex flex-col">
      {/* Circular Progress Section */}
      <div className="flex justify-around w-full cursor-pointer">
        <div className="width-[50%] flex items-center">
        <div onClick={()=>pcardopen(data.id)} className="relative flex justify-center items-center w-[65px] h-[65px] ">
        <div
          className="relative flex justify-center items-center w-[65px] h-[65px] rounded-full"
          style={circleStyle}
        >
          <div className="flex justify-center items-center w-[55px] h-[55px] bg-[#FAFAF9] rounded-full">
            <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#C4C4C4] rounded-full"></div>
          </div>
          <div style={smallCircleStyle} className="flex justify-center items-center">
            <div className="text-[8px] font-bold text-black">{percentage}%</div>
          </div>
        </div>
      </div>
        </div>
     
      <div className="flex flex-col items-start text-xs text-gray-600">
          <div className="flex items-center mb-1">
            <img src={clockicon} alt="Clock Icon" className="w-3 h-3 mr-1" />
            <span className="text-[#1d2951] text-xs">{data.start_time||"-"}</span>
          </div>
          <div className="flex items-center mb-2">
            <img src={makidoctor} alt="Doctor Icon" className="w-3 h-3 mr-1" />
            <span className="text-[#1d2951] text-xs">{data.linked_provider||"-"}</span>
          </div>
          <span className="text-xs text-gray-400">Follow Up</span>
          <span className="text-xs text-gray-400">Labs</span>
          <span className="text-xs text-gray-400">Ultrasound</span>
        </div>
      </div>
      

      {/* Text and Button Section */}
      <div className=" flex flex-col justify-between flex-grow">
        <div className="flex justify-between w-full mt-3">
        <div className="text-center w-[50%] mr-[20px]">
          <h3 className="text-black font-semibold text-sm">{(data.firstname+" "+data.lastname)||"-"}</h3>
          <div className="flex gap-1">
          <p className="text-[#989898] text-sm">#{data.appointmentid||'-'}</p>
          <img onClick={()=>setIsStatusNotesPopUp(true)}src={calenderIcon} alt="/Calender" style={{color:'#505050',width:'18px'}}/>
        </div>
        </div>
        <div className="flex  flex-wrap">
  <p
    className="text-xs text-[#505050] mt-1 break-words"
    style={{
      fontSize: "12px",
      overflowWrap: "break-word", // Break long words
      wordBreak: "break-word",    // Break words to prevent overflow
      maxWidth: "100%",           // Prevent text from going outside the container width
      whiteSpace: "normal",       // Allow text wrapping
    }}
  >
    {data.insurancename || "-"}
  </p>
</div>

        </div>
    
        <div className="flex relative items-center justify-between mt-5  pl-6">
          <button onClick={()=>setIsOrdersOverlay(true)} className="bg-[#D97842] text-white text-[12px] px-6 rounded-full">Orders</button>
          {data.room_no?<div onClick={()=>modalopen(data.id)}  className="flex flex-col justify-around items-center absolute bottom-[-24px] right-[-24px] bg-[#F6E2DB] text-[#D97842] p-2 h-[80px] w-[60px] rounded-tl-2xl  rounded-bl-md rounded-br-[32px]">
           <div >
            <img src={doorIcon} style={{width:'17px'}} alt="/DoorIcon"/>
           </div>
            <div className="text-[13px]">{data.room_no||"-"}</div>
          </div>:  <div onClick={()=>modalopen(data.id)}  ><svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg></div>}
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
