import { List, ListItem, ListItemIcon, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupIcon from '@mui/icons-material/Group';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { setMiniSidenav } from 'context';
import { useSoftUIController } from 'context';
import { useNavigate } from 'react-router-dom';

const CustomSideNav = () => {
  const [isCollapsed, setIsCollapsed] = useState(0);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const navigate = useNavigate();

  const handleCollapse = () => {
    setMiniSidenav(dispatch, !miniSidenav);
    setIsCollapsed(1);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth'); // Remove authentication token or session
    navigate('/brainycare/sign-in/'); // Navigate to the sign-in page
  };

  const navItems = [
    { label: 'Home', icon: <HomeIcon style={{ color: 'black' }} /> },
    { label: 'Email', icon: <EmailIcon style={{ color: 'black' }} /> },
    { label: 'Fax', icon: <FaxIcon style={{ color: 'black' }} /> },
    { label: 'Voicemail', icon: <VoicemailIcon style={{ color: 'black' }} /> },
    { label: 'RPM', icon: <DashboardIcon style={{ color: 'black' }} /> },
    { label: 'User', icon: <PersonIcon style={{ color: 'black' }} /> },
    { label: 'Reports', icon: <ReportIcon style={{ color: 'black' }} /> },
    { label: 'Billing', icon: <PaymentIcon style={{ color: 'black' }} /> },
    { label: 'ACO', icon: <GroupIcon style={{ color: 'black' }} /> },
    { divider: true }, // Divider here
    { label: 'Support', icon: <HelpOutlineIcon style={{ color: 'black' }} /> },
    {
      label: 'Logout',
      icon: <ExitToAppIcon style={{ color: 'black' }} />,
      onClick: handleLogout, // Add onClick handler for logout
    },
  ];

  return (
    <div
      className={`fixed z-10 items-center top-18 left-0 h-full bg-[#FAFAF9] rounded-b-lg shadow-[0px_14px_42px_0px_rgba(0,_0,_0,_0.06)] transition-all ${miniSidenav ? 'w-[5%]' : 'w-[18%]'}`}
    >
      <div className="flex items-center justify-end p-2">
        <div onClick={handleCollapse}>
          {/* Chevron toggle icon */}
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48" fill="none">
            <g filter="url(#filter0_d_787_7715)">
              <circle cx="24" cy="24" r="16" transform="rotate(-180 24 24)" fill="#E17954" />
            </g>
            <foreignObject x="16.5" y="9" width="15" height="30" style={{ cursor: 'pointer' }}>
              {miniSidenav ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="30" viewBox="0 0 15 30" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6962 15.8887L5.62492 22.96L3.85742 21.1925L10.0449 15.005L3.85742 8.81749L5.62492 7.04999L12.6962 14.1212C12.9305 14.3556 13.0622 14.6735 13.0622 15.005C13.0622 15.3364 12.9305 15.6543 12.6962 15.8887Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="30" viewBox="0 0 15 30" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.30383 14.1113L9.37508 7.04001L11.1426 8.80751L4.95508 14.995L11.1426 21.1825L9.37508 22.95L2.30383 15.8788C2.06949 15.6444 1.93785 15.3265 1.93785 14.995C1.93785 14.6636 2.06949 14.3457 2.30383 14.1113Z"
                    fill="white"
                  />
                </svg>
              )}
            </foreignObject>
            <defs>
              <filter id="filter0_d_787_7715" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_787_7715" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_787_7715" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-2">
        <List>
          {navItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.divider ? (
                <Divider style={{ margin: '8px 0' }} />
              ) : (
                  
                <ListItem button style={{ padding: '12px 0' }} onClick={item.label === 'Logout' ? handleLogout : item.onClick}>
                  <ListItemIcon style={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  {!miniSidenav && (
                    <div className="[font-family:'Tahoma'] text-[#1d2951] text-xs  leading-[normal] ml-2">
                      {item.label}
                    </div>
                  )}
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    </div>
  );
};

export default CustomSideNav;
