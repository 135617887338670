import PageLayout from 'examples/LayoutContainers/PageLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loginprofilepic from '../../assets/images/team-1.jpg';
import api from 'api/api';

const LoginForm = () => {
  const [password, setPassword] = useState('Chim@123');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const username = localStorage.getItem('user'); 
  const params = new URLSearchParams();
  const handleLogin = async() => {
    params.append('username', username);
    params.append('password', password);
    params.append('client_id', '9928');    
    try {
      const tokenresp = await api.post('/auth/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    
      if (tokenresp.status === 200) {
        const { auth, access_token, data,id } = tokenresp;
        
        if(access_token){
          setError('')
          localStorage.setItem('auth', access_token);
          localStorage.setItem('user',data[0]?.username)
          navigate('/dashboards/default')

        }else{
          setError("failed to authenticates");

        } 
       
      } else {
        setError("failed to authenticate");
        console.error('Failed to authenticate:', tokenresp.statusText);
      }
    } catch (error) {
      // Handle errors such as network issues or invalid responses
      console.error('An error occurred during authentication:', error);
      setError("something went wrong");
    }
  };

 

  const handleSwitchUser = () => {
    navigate('/authentication/sign-in/basic');
  };

  return (
    <PageLayout>
      <div className="bg-[#1d2951] flex justify-center items-center w-full h-[100vh]">
        <div>
          <div className="bg-white rounded-2xl p-12 flex flex-col justify-center w-[450px] shadow-lg">
            <div className='flex flex-col justify-center items-center'>
              <div className='flex justify-center items-center rounded-full' style={{border: '6px solid grey', width: 'fit-content', padding: '5px'}}>
                <img
                  src={Loginprofilepic}
                  alt="Profile"
                  className="w-16 h-16 rounded-full object-cover"
                />
              </div>
              <div className='text-wrapper mt-4 text-xl font-semibold'>
              {username}
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col'>
                <label htmlFor="password" className="text-sm text-[#666666] mt-4">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-gray-300 rounded-md p-2 text-sm"
                />
              </div>
              {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
              <u href="#" className="text-orange-500 text-sm mt-2">
                Forget Password?
              </u>
              <button
                onClick={handleLogin}
                className="bg-[#E17954] text-white p-3 text-sm rounded-3xl mt-4 w-full"
              >
                Log in
              </button>
              <div className='flex justify-between text-xs pl-3 pr-3'>
                <div className='text-[#666666]'>
                  Support
                </div>
                <div className='text-[#666666]'>
                  FAQ
                </div>
              </div>
            </div>
          </div>
          <div className='p-4 flex justify-center'>
            <button
              onClick={handleSwitchUser}
              className="text-white p-3 text-sm rounded-3xl mt-4 w-[83%]"
              style={{border:'1px solid #E17954'}}
            >
              Switch User
            </button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoginForm;
