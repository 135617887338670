import React, { useState } from 'react';
import ChevronDown from '../../../../../assets/patientCharts/Chevron_Down.png';

const options = {
    vitals: [
        'Multiple joint pain (finding)',
        'Malaise (finding)',
        'Guillain-Barre syndrome (disorder)',
        'Unsp injury of right wrist, hand & finger(s), sequela'
    ],
    dx: [
        'Multiple joint pain (finding)',
        'Malaise (finding)',
        'Guillain-Barre syndrome (disorder)',
        'Unsp injury of right wrist, hand & finger(s), sequela'
    ]
};

export const DropDownFrame = () => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({ vitals: '', dx: '' });

    const handleToggle = (dropdown) => {
        setOpenDropdown(prev => prev === dropdown ? null : dropdown);
    };

    const handleSelect = (dropdown, option) => {
        setSelectedOptions(prev => ({
            ...prev,
            [dropdown]: option
        }));
        setOpenDropdown(null);
    };

    return (
        <div className="flex gap-[32px] pl-4">
            {['vitals', 'dx'].map((dropdown) => (
                <div key={dropdown} className="relative w-[220px] h-9 bg-white rounded-[38px] border border-solid border-[#eeeeee] flex items-center px-4">
                    <div 
                        className="text-[#1d2951] text-sm font-normal leading-[normal] flex-grow truncate"
                    >
                        {selectedOptions[dropdown] || (dropdown === 'vitals' ? 'Vitals' : 'DX')}
                    </div>
                    <div 
                        className={`absolute right-1 top-1/2 transform ${openDropdown === dropdown ? '-translate-y-1/2 rotate-180' : '-translate-y-1/2'} bg-[#f6f6f6] rounded-full w-7 h-7 flex items-center justify-center cursor-pointer transition-transform`}
                        onClick={() => handleToggle(dropdown)}
                    >
                        <img src={ChevronDown} alt="Chevron Down" className="w-4 h-4" />
                    </div>
                    {openDropdown === dropdown && (
                        <div className="absolute z-10 left-0 top-10 bg-white border border-solid border-[#eeeeee] rounded-lg shadow-lg w-auto max-w-fit flex-wrap">
                            {options[dropdown].map((option, index) => (
                                <div
                                    key={index}
                                    className="p-2 cursor-pointer hover:bg-[#f6f6f6] text-xs whitespace-nowrap overflow-hidden text-ellipsis"
                                    onClick={() => handleSelect(dropdown, option)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
