import React, { useEffect, useState, useCallback } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DetailsNavbar from "./screen/detailsnav";
import TabsSwitchNav from "./screen/tabsnav";
import RoomCard from "./cards/RoomCard";
import RoomGridCard from "./cards/RoomGridCard";
import ModalOverlay from "./modal/modaloverlay";
import api from "api/api";
import { useSoftUIController } from "context";
import { setLayout } from "context";
import PcardPopup from "./popup";
import { CircularProgress } from "@mui/material";
import OrdersOverlay from "./overlays/ordersoverlay";
import StatusNotesPopUp from "./overlays/statusnotesoverlay";
import debounce from 'lodash.debounce'; // Import debounce function
import dayjs from 'dayjs';

const formatDate = (dateString) => {
  return dayjs(dateString).format('YYYY-MM-DD');
};
const MainLandingScreen = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isGrid, setIsGrid] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrdersOverlay, setIsOrdersOverlay] = useState(false);
  const [isStatusNotesPopUp, setIsStatusNotesPopUp] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const [isPcard, setIsPcard] = useState(false);
  const [dashboarddata, setDashboardData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originaldata,setOriginaldata]=useState([])
  const [selectedCard, setSelectedCard] = useState({});
  const [ihu, setIhu] = useState(formatDate(new Date()));
  const [offset, setOffset] = useState(0); // Track the offset for pagination
  const [limit, setLimit] = useState(6);
  // const limit = 6; // Define how many cards to load each time

  const changeTabs = (x) => {
    setActiveTab(x);
    setOffset(0); 
    setDashboardData([]); 
    
  };


  const fetchDashboardData = async (newOffset = offset) => {
    if (isLoading) return;
    try {
      setIsLoading(true);

      const response = await api.post(
        "/v1/9928/appointments/dashboard",
        {
          appointmentdate: ihu,
          searchflag: filteredData.length > 0 ? filteredData.map((item) => item.id).join(",") : null,
          dashfilter: activeTab,
          filterarr: [],
          start: newOffset,
          limit: limit,
        }
      );

      if (response.status === 200) {
      const newData = response.data || [];
      const filteredResults = newData.filter(item => dayjs(item.appointmentdate).isSame(dayjs(ihu), 'day'));
      setDashboardData(filteredResults);
      // Update the state with only the filtered results
      setOriginaldata(filteredResults);
      


        setOriginaldata((prevData) => [...prevData, ...(response.data || [])])
        //setDashboardData((prevData) => [...prevData, ...(response.data || [])]); // Append new data without changing existing positions
        setOffset(newOffset + limit); // Increment offset by limit
        setLimit(limit+6);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDBcount = async () => {
    try {
      await api.post(
        "/v1/9928/appointments/dbscounts",
        {
          type: "RPM",
          userid: "all",
          searchdate: "2024-01-08",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error fetching DB count:", error);
    }
  };


  // Debounced scroll handler
  const handleScroll = useCallback(
    debounce(() => {
      const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 50;
      if (bottom && !isLoading) {
        fetchDashboardData(offset); // Load more data when the user scrolls near the bottom
      }
    }, 300), [offset, isLoading] // 300ms debounce delay
  );
useEffect(()=>{
setDashboardData(originaldata)
},[originaldata])


  useEffect(() => {
    fetchDashboardData(0); // Load initial data when activeTab changes
    fetchDBcount();
  }, [activeTab , ihu]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const openModal = (x) => {
    setSelectedCard(dashboarddata?.find((y) => y.id === x));
    setIsModalOpen(true);
  };

  const pcardopen = (x) => {
    setSelectedCard(dashboarddata?.find((y) => y.id === x));
    setIsPcard(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLayout(dispatch, "dashboard");
  };

  return (
    <div>
      <DashboardLayout>
        <div className="sticky bg-[#fafaf9] top-16 z-10">
          <DetailsNavbar
            refresh={() => fetchDashboardData(0)}
            dashboarddata={originaldata}
            setFilteredData={setFilteredData}
            setDashboardData={setDashboardData}
            filteredData={filteredData}
          />
          <TabsSwitchNav
            onTabClick={changeTabs}
            handleView={setIsGrid}
            view={isGrid}
            activeTab={activeTab}
            a={setIhu}
          />
        </div>

        {/* Responsive Card Layout */}
        {!isGrid ? (
          <div className={`flex flex-wrap gap-6 mt-4 ${isLoading && dashboarddata.length === 0 ? "items-center h-[350px] justify-center" : "justify-start"}`}>
            {isLoading && dashboarddata.length === 0 ? (
              <div><CircularProgress /></div>
            ) : (
              dashboarddata.length > 0 ? (
                dashboarddata.map((x, index) => (
                  <React.Fragment key={index}>
                    <RoomCard
                      setIsStatusNotesPopUp={setIsStatusNotesPopUp}
                      setIsOrdersOverlay={setIsOrdersOverlay}
                      modalopen={openModal}
                      pcardopen={pcardopen}
                      data={x}
                    />
                  </React.Fragment>
                ))
              ) : (
                <div>No data available</div>
              )
            )}
          </div>
        ) : (
          <div className={`flex flex-wrap gap-6 mt-4 ${isLoading && dashboarddata.length === 0 ? "items-center h-[350px] justify-center" : "justify-start"}`}>
            {isLoading && dashboarddata.length === 0 ? (
              <div><CircularProgress /></div>
            ) : (
              dashboarddata.length > 0 ? (
                dashboarddata.map((x, index) => (
                  <React.Fragment key={index}>
                    <RoomGridCard
                      setIsStatusNotesPopUp={setIsStatusNotesPopUp}
                      modalopen={openModal}
                      setIsOrdersOverlay={setIsOrdersOverlay}
                      pcardopen={pcardopen}
                      data={x}
                    />
                  </React.Fragment>
                ))
              ) : (
                <div>No data available</div>
              )
            )}
          </div>
        )}
      </DashboardLayout>
      <PcardPopup setPcard={setIsPcard} data={selectedCard} isPcard={isPcard} />
      <ModalOverlay modaldata={selectedCard} isOpen={isModalOpen} onClose={closeModal} />
      <OrdersOverlay isorderspopup={isOrdersOverlay} setIsOrdersOverlay={setIsOrdersOverlay} />
      <StatusNotesPopUp isStatusNotesPopUp={isStatusNotesPopUp} setIsStatusNotesPopUp={setIsStatusNotesPopUp} />
    </div>
  );
};

export default MainLandingScreen;
