import React from "react";

const TabsComponent = ({ tabs, selectedTab, onSelectTab }) => {
  return (
    <div className="w-full h-[34px]  flex-shrink-0 relative">
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => onSelectTab(index)}
          className={`[font-family:'Inter-Regular',Helvetica] w-[48px] tracking-[0] text-lg absolute font-normal whitespace-nowrap leading-5 cursor-pointer ${
            selectedTab === index ? "text-[#e17954]" : "text-[#1d2951]"
          }`}
          style={{ left: `${5 + index * 75}px`,fontSize:'15px' }}
        >
          {tab.label}
        </div>
      ))}
      <div
        className={`top-[20px] h-[6px] rounded-sm bg-[#e17954] absolute`}
        style={{
          left: `${2 + selectedTab * 75}px`,
          width: `${tabs[selectedTab].width}px`,
        }}
      />
    </div>
  );
};

export default TabsComponent;
