import { useState } from "react";
import volume from '../../../../../assets/patientCharts/Player Volume.png'
import play from '../../../../../assets/patientCharts/Play.png'
import roundmusic from '../../../../../assets/patientCharts/roundmusic.png'
import waveform from '../../../../../assets/patientCharts/Waveform.png'
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const AudioCardGrid = () => {
  const [percentage, setPercentage] = useState(20);
  const circleStyle = {
    background: `conic-gradient(#1D2951 ${percentage * 3.6}deg, #EDEDED ${
      percentage * 3.6
    }deg)`,
  };

  return (
    <>
      <div className="w-[383px] h-[164px] flex flex-col items-start justify-between p-6 bg-white rounded-[15px] shadow-md border-t-2 border-[#1d2951]">
        <div className=" w-full flex  justify-between items-center gap-2">
          <div className=" [font-family: 'Inter-Bold',Helvetica] text-center font-bold text-[#141515] text-[13px] tracking-[0] leading-[normal] whitespace-nowrap">
            Wednesday, August 14 2024
          </div>
          <div className="flex  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M18.5333 6.08429L20.916 8.46592M20.0655 3.98617L13.6226 10.429C13.2897 10.7615 13.0627 11.185 12.9701 11.6463L12.375 14.6253L15.354 14.029C15.8152 13.9368 16.2382 13.7107 16.5712 13.3777L23.0141 6.93479C23.2077 6.74118 23.3613 6.51134 23.4661 6.25837C23.5709 6.00541 23.6248 5.73429 23.6248 5.46048C23.6248 5.18668 23.5709 4.91555 23.4661 4.66259C23.3613 4.40963 23.2077 4.17978 23.0141 3.98617C22.8205 3.79256 22.5907 3.63898 22.3377 3.5342C22.0847 3.42942 21.8136 3.37549 21.5398 3.37549C21.266 3.37549 20.9949 3.42942 20.7419 3.5342C20.489 3.63898 20.2591 3.79256 20.0655 3.98617V3.98617Z"
                stroke="#1D2951"
                stroke-width="2.56214"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.3755 16.875V20.25C21.3755 20.8467 21.1384 21.419 20.7165 21.841C20.2945 22.2629 19.7222 22.5 19.1255 22.5H6.75049C6.15375 22.5 5.58145 22.2629 5.1595 21.841C4.73754 21.419 4.50049 20.8467 4.50049 20.25V7.875C4.50049 7.27826 4.73754 6.70597 5.1595 6.28401C5.58145 5.86205 6.15375 5.625 6.75049 5.625H10.1255"
                stroke="#1D2951"
                stroke-width="2.56214"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M7.02 25C6.44417 25 5.96375 24.8075 5.57875 24.4225C5.19375 24.0375 5.00083 23.5567 5 22.98V7.02C5 6.44417 5.19292 5.96375 5.57875 5.57875C5.96458 5.19375 6.445 5.00083 7.02 5H22.9813C23.5562 5 24.0367 5.19292 24.4225 5.57875C24.8083 5.96458 25.0008 6.445 25 7.02V22.9813C25 23.5562 24.8075 24.0367 24.4225 24.4225C24.0375 24.8083 23.5567 25.0008 22.98 25H7.02ZM7.02 23.75H22.9813C23.1729 23.75 23.3492 23.67 23.51 23.51C23.6708 23.35 23.7508 23.1733 23.75 22.98V7.02C23.75 6.8275 23.67 6.65083 23.51 6.49C23.35 6.32917 23.1733 6.24917 22.98 6.25H7.02C6.8275 6.25 6.65083 6.33 6.49 6.49C6.32917 6.65 6.24917 6.82667 6.25 7.02V22.9813C6.25 23.1729 6.33 23.3492 6.49 23.51C6.65 23.6708 6.82625 23.7508 7.01875 23.75M9.375 20.625H20.8175L17.2837 15.9125L14.015 20.0475L11.8275 17.4038L9.375 20.625Z"
                fill="#1D2951"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5399 18.2001H6.4999V19.5001H7.5399C7.9299 19.5001 8.1899 19.2401 8.1899 18.8501C8.1899 18.4601 7.9299 18.2001 7.5399 18.2001ZM14.2999 2.6001H3.8999V23.4001H20.7999V9.1001L14.2999 2.6001ZM9.3599 18.9801C9.3599 20.0201 8.5799 20.8001 7.5399 20.8001H6.4999V22.1001H5.1999V16.9001H7.5399C8.5799 16.9001 9.3599 17.6801 9.3599 18.7201V18.9801ZM14.6899 19.6301C14.6899 20.9301 13.6499 22.1001 12.2199 22.1001H10.3999V16.9001H12.2199C13.5199 16.9001 14.6899 17.9401 14.6899 19.3701V19.6301ZM19.4999 18.2001H16.8999V19.5001H18.8499V20.8001H16.8999V22.1001H15.5999V16.9001H19.4999V18.2001ZM19.4999 15.6001H5.1999V3.9001H14.2999V9.1001H19.4999V15.6001ZM12.2199 18.2001H11.6999V20.8001H12.2199C12.9999 20.8001 13.5199 20.2801 13.5199 19.5001C13.5199 18.7201 12.8699 18.2001 12.2199 18.2001Z"
                fill="#1D2951"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-between items-center w-full ">
          <div
            className="relative flex justify-center items-center w-[60px] h-[60px] rounded-full"
            style={circleStyle}
          >
            <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#FFFFFF] rounded-full">
              <div className=" [font-family:'Roboto-SemiBold',Helvetica] font-semibold text-[#1d2951] text-xs tracking-[0] leading-[normal] whitespace-nowrap">
                {percentage}%
              </div>
            </div>
          </div>
          <div className="inline-flex items-center gap-3 pl-2 pr-3 py-2 relative bg-lightbackground-secondary rounded-3xl overflow-hidden border border-solid border-black  bg-[#F2F3F5]">
  <div className='flex items-center justify-between gap-2'>
  <div className="relative">
      <img src={roundmusic} alt="Music Round" />
      <img src={play} alt="Play" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
   <div><img src={waveform} alt="" /></div>
    <div className="bg-blend-saturation opacity-[0.66] [font-family:'Noto_Sans-Regular',Helvetica] font-normal text-black text-sm tracking-[0] leading-4 whitespace-nowrap">
      0:00
    </div>
  <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill='#000000'>
  <g opacity="0.66">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "saturation" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "difference" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "saturation" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
  </g>
</svg>
</div>
  </div>
</div>

        </div>
      </div>
    </>
  );
};
export const Audiocards=()=>{
  const [percentage, setPercentage] = useState(100);
  const circleStyle = {
    background: `conic-gradient(#1D2951 ${percentage * 3.6}deg, #EDEDED ${
      percentage * 3.6
    }deg)`,
  };

  return (<>  <div
    className="flex flex-col sm:flex-row justify-between min-h-[38px] items-center w-full max-w-[910px] p-[16px] rounded-[12px] border border-[#232323] border-opacity-10 bg-[#FFF]"
  >
    <div className="flex flex-col sm:flex-row items-center justify-between w-full sm:w-auto gap-4 sm:gap-20">
        
      <div className="flex items-center gap-4">      
       <AccessTimeIcon/>        
        <div className="text-[#232323] font-inter text-[16px] font-normal leading-[20px]">
          August 14, 2024
        </div>
      
      </div>
      <div className="flex gap-5">
      <div
            className="relative flex justify-center items-center w-[60px] h-[60px] rounded-full"
            style={circleStyle}
          >
            <div className="flex justify-center items-center w-[45px] h-[45px] bg-[#FFFFFF] rounded-full">
              <div className=" [font-family:'Roboto-SemiBold',Helvetica] font-semibold text-[#1d2951] text-xs tracking-[0] leading-[normal] whitespace-nowrap">
                {percentage}%
              </div>
            </div>
          </div>
      <div className="inline-flex items-center gap-3 pl-2 pr-3 py-2 relative bg-lightbackground-secondary rounded-3xl overflow-hidden border border-solid border-black  bg-[#F2F3F5]">
  <div className='flex items-center justify-between gap-2'>
  <div className="relative">
      <img src={roundmusic} alt="Music Round" />
      <img src={play} alt="Play" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
    </div>
   <div><img src={waveform} alt="" /></div>
    <div className="bg-blend-saturation opacity-[0.66] [font-family:'Noto_Sans-Regular',Helvetica] font-normal text-black text-sm tracking-[0] leading-4 whitespace-nowrap">
      0:00
    </div>
  <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill='#000000'>
  <g opacity="0.66">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "saturation" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "difference" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "saturation" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000'    style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.383 3.57892C11.009 3.42492 10.579 3.50992 10.293 3.79592L6 8.50192H3C2.45 8.50192 2 8.95292 2 9.50192V15.5019C2 16.0519 2.45 16.5019 3 16.5019H6L10.293 21.2099C10.579 21.4959 11.009 21.5819 11.383 21.4269C11.757 21.2719 12 20.9069 12 20.5019V4.50192C12 4.09892 11.757 3.73192 11.383 3.57892ZM14 5.50183V7.50183C16.757 7.50183 19 9.74583 19 12.5019C19 15.2589 16.757 17.5019 14 17.5019V19.5019C17.86 19.5019 21 16.3629 21 12.5019C21 8.64283 17.86 5.50183 14 5.50183ZM14 9.50183C15.654 9.50183 17 10.8489 17 12.5019C17 14.1569 15.654 15.5019 14 15.5019V13.5019C14.551 13.5019 15 13.0529 15 12.5019C15 11.9509 14.551 11.5019 14 11.5019V9.50183Z" fill='#000000' style={{ mixBlendMode: "overlay" }}/>
  </g>
</svg>
</div>
  </div>
</div>
</div>
    </div>

    <div className="text-[#1D2951] text-center font-inter text-[13px] font-light mt-4 sm:mt-0 ml-auto flex items-center gap-2">
    <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M7.02 25C6.44417 25 5.96375 24.8075 5.57875 24.4225C5.19375 24.0375 5.00083 23.5567 5 22.98V7.02C5 6.44417 5.19292 5.96375 5.57875 5.57875C5.96458 5.19375 6.445 5.00083 7.02 5H22.9813C23.5562 5 24.0367 5.19292 24.4225 5.57875C24.8083 5.96458 25.0008 6.445 25 7.02V22.9813C25 23.5562 24.8075 24.0367 24.4225 24.4225C24.0375 24.8083 23.5567 25.0008 22.98 25H7.02ZM7.02 23.75H22.9813C23.1729 23.75 23.3492 23.67 23.51 23.51C23.6708 23.35 23.7508 23.1733 23.75 22.98V7.02C23.75 6.8275 23.67 6.65083 23.51 6.49C23.35 6.32917 23.1733 6.24917 22.98 6.25H7.02C6.8275 6.25 6.65083 6.33 6.49 6.49C6.32917 6.65 6.24917 6.82667 6.25 7.02V22.9813C6.25 23.1729 6.33 23.3492 6.49 23.51C6.65 23.6708 6.82625 23.7508 7.01875 23.75M9.375 20.625H20.8175L17.2837 15.9125L14.015 20.0475L11.8275 17.4038L9.375 20.625Z"
                fill="#1D2951"
              />
            </svg>
             <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M18.5333 6.08429L20.916 8.46592M20.0655 3.98617L13.6226 10.429C13.2897 10.7615 13.0627 11.185 12.9701 11.6463L12.375 14.6253L15.354 14.029C15.8152 13.9368 16.2382 13.7107 16.5712 13.3777L23.0141 6.93479C23.2077 6.74118 23.3613 6.51134 23.4661 6.25837C23.5709 6.00541 23.6248 5.73429 23.6248 5.46048C23.6248 5.18668 23.5709 4.91555 23.4661 4.66259C23.3613 4.40963 23.2077 4.17978 23.0141 3.98617C22.8205 3.79256 22.5907 3.63898 22.3377 3.5342C22.0847 3.42942 21.8136 3.37549 21.5398 3.37549C21.266 3.37549 20.9949 3.42942 20.7419 3.5342C20.489 3.63898 20.2591 3.79256 20.0655 3.98617V3.98617Z"
                stroke="#1D2951"
                stroke-width="2.56214"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.3755 16.875V20.25C21.3755 20.8467 21.1384 21.419 20.7165 21.841C20.2945 22.2629 19.7222 22.5 19.1255 22.5H6.75049C6.15375 22.5 5.58145 22.2629 5.1595 21.841C4.73754 21.419 4.50049 20.8467 4.50049 20.25V7.875C4.50049 7.27826 4.73754 6.70597 5.1595 6.28401C5.58145 5.86205 6.15375 5.625 6.75049 5.625H10.1255"
                stroke="#1D2951"
                stroke-width="2.56214"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
           
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.5399 18.2001H6.4999V19.5001H7.5399C7.9299 19.5001 8.1899 19.2401 8.1899 18.8501C8.1899 18.4601 7.9299 18.2001 7.5399 18.2001ZM14.2999 2.6001H3.8999V23.4001H20.7999V9.1001L14.2999 2.6001ZM9.3599 18.9801C9.3599 20.0201 8.5799 20.8001 7.5399 20.8001H6.4999V22.1001H5.1999V16.9001H7.5399C8.5799 16.9001 9.3599 17.6801 9.3599 18.7201V18.9801ZM14.6899 19.6301C14.6899 20.9301 13.6499 22.1001 12.2199 22.1001H10.3999V16.9001H12.2199C13.5199 16.9001 14.6899 17.9401 14.6899 19.3701V19.6301ZM19.4999 18.2001H16.8999V19.5001H18.8499V20.8001H16.8999V22.1001H15.5999V16.9001H19.4999V18.2001ZM19.4999 15.6001H5.1999V3.9001H14.2999V9.1001H19.4999V15.6001ZM12.2199 18.2001H11.6999V20.8001H12.2199C12.9999 20.8001 13.5199 20.2801 13.5199 19.5001C13.5199 18.7201 12.8699 18.2001 12.2199 18.2001Z"
                fill="#1D2951"
              />
            </svg>
    </div>
  </div></>)
}