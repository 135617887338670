import React, { useState } from 'react';
import Select from "react-select";
import DoneIcon from "@mui/icons-material/Done";
import UnitSelector from './UnitSelector';

const Intake = () => {
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleClickItem = (unit) => {
    setSelectedUnit(unit);
  };
  const BoxRound = () => {
    return (
      <div style={{ height: "7px", width: "7px" }}>
        <div
          style={{
            backgroundColor: "#e17954",
            borderRadius: "3.5px",
            height: "7px",
            width: "7px",
          }}
        />
      </div>
    );
  };

  // Define unique options for each dropdown
  const heightOptions = [
    { value: "standing", label: "Standing", icon: <DoneIcon /> },
    { value: "preoperative", label: "Preoperative", icon: <DoneIcon /> },
    { value: "stated", label: "Stated", icon: <DoneIcon /> },
    { value: "sitting", label: "Sitting", icon: <DoneIcon /> },
  ];

  const WeightOptions = [
    { value: "with Cloths", label: "with Cloths", icon: <DoneIcon /> },
    { value: "without Cloths", label: "without Cloths", icon: <DoneIcon /> },
  ];

  const bloodPressurePositionOptions = [
    { value: "L arm", label: "L arm", icon: <DoneIcon /> },
    { value: "R arm", label: "R arm", icon: <DoneIcon /> },
    { value: "L wrist", label: "L wrist", icon: <DoneIcon /> },
    { value: "R wrist", label: "R wrist", icon: <DoneIcon /> },
    { value: "L Leg", label: "L Leg", icon: <DoneIcon /> },
    { value: "R Leg", label: "R Leg", icon: <DoneIcon /> },
  ];

  const bloodPressureTimingOptions = [
    { value: "morning", label: "Morning", icon: <DoneIcon /> },
    { value: "afternoon", label: "Afternoon", icon: <DoneIcon /> },
    { value: "evening", label: "Evening", icon: <DoneIcon /> },
  ];

  const temperatureOptions = [
    { value: "Ear", label: "Ear", icon: <DoneIcon /> },
    { value: "oral", label: "Oral", icon: <DoneIcon /> },
    { value: "Dry", label: "Dry", icon: <DoneIcon /> },
    { value: "axillary", label: "Axillary", icon: <DoneIcon /> },
    { value: "Stated", label: "Stated", icon: <DoneIcon /> },
    { value: "Forehead", label: "Forehead", icon: <DoneIcon /> },
    { value: "Preoperative", label: "Preoperative", icon: <DoneIcon /> },
  ];

  const pulseOptions = [
    { value: "regular", label: "Regular", icon: <DoneIcon /> },
    { value: "irregular", label: "Irregular", icon: <DoneIcon /> },
    { value: "Regular Irregular", label: "Regular Irregular", icon: <DoneIcon /> },
    { value: "Irregular regular", label: "Irregular regular", icon: <DoneIcon /> },

  ];

  const o2SaturationOptions = [
    { value: "roomAir", label: "Room Air", icon: <DoneIcon /> },
    { value: "oxygen", label: "On Oxygen", icon: <DoneIcon /> },
  ];

  const respiratoryOptions = [
    { value: "normal", label: "Normal", icon: <DoneIcon /> },
    { value: "labored", label: "Labored", icon: <DoneIcon /> },
    { value: "irregular", label: "Irregular", icon: <DoneIcon /> },
  ];

  const customOption = (props) => {
    const {
      innerProps,
      innerRef,
      data: { label, icon },
    } = props;

    return (
      <div ref={innerRef} {...innerProps} className="flex items-center p-2">
        <span className="mr-2">{icon}</span>
        {label}
      </div>
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#ddd',
      borderWidth: '1px',
      borderRadius: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#aaa',
      },
      padding: '2px',
      minHeight: '40px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '4px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0 6px',
    }),
  };

  return (
    <div className="p-6 bg-white rounded-lg  text-xs h-auto">
      {/* Height Section */}
      <div className="gap-4 mb-4 flex flex-col">
        <div>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Height</label>
            <div className="items-center text-sm flex gap-2">
              <BoxRound />
              <div style={{ color: "#e17954" }}>5 ft 3 in 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="Ft" />
              <div>Ft</div>
            </div>
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-md p-3" placeholder="In" />
              <div>In</div>
            </div>
            <Select options={heightOptions} components={{ Option: customOption }} styles={customStyles} className="pr-10 cursor-pointer"  />
          </div>
        </div>

        {/* Weight Section */}
        <div className='mt-5'>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Weight</label>
            <div className="items-center text-sm flex gap-2">
              <BoxRound />
              <div style={{ color: "#e17954" }}>135 lbs 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2 mt-2">
            <div className="flex items-center">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="Wt" />
            </div>
            <div className="items-center flex gap-2">
      <UnitSelector text="lbs" width="40px" isSelected={selectedUnit === 'lbs'}  onClick={() => handleClickItem('lbs')} />
      <UnitSelector text="kg" width="40px" isSelected={selectedUnit === 'kg'}  onClick={() => handleClickItem('kg')} />
      <UnitSelector  text="Stone"  width="45px"   isSelected={selectedUnit === 'Stone'}  onClick={() => handleClickItem('Stone')}/>
       </div>  
       <Select options={WeightOptions} components={{ Option: customOption }} styles={customStyles}  className="pr-10 cursor-pointer" />
       </div>
      </div>

        {/* Blood Pressure Section */}
        <div className='mt-5'>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Blood Pressure</label>
            <div className="items-center text-sm flex gap-2">
              <BoxRound />
              <div style={{ color: "#e17954",display:'flex' }}> 112/4 <pre>   </pre>   04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="H-bp" />
              <div>/</div>
            </div>
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-md p-3" placeholder="L-bp" />
            </div>
            <Select
              options={heightOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10 cursor-pointer"
            />
             <Select
              options={bloodPressurePositionOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10 cursor-pointer"
            />
          </div>
        </div>

        {/* Temperature Section */}
        <div className='mt-5'>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Temparature</label>
            <div className="items-center text-sm flex gap-2">
              <div className='text-xs'>98 taken on 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2 mt-2">
            <div className="flex items-center">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="Temp" />
            </div>
            
            <div className="items-center flex gap-2">
     <UnitSelector text="°F" width="40px" isSelected={selectedUnit === '°F'} onClick={() => handleClickItem('°F')} />
      <UnitSelector  text="°C"  width="40px"  isSelected={selectedUnit === '°C'}   onClick={() => handleClickItem('°C')} />
      <UnitSelector  text="K"  width="45px"  isSelected={selectedUnit === 'K'} onClick={() => handleClickItem('K')}  />
            </div>
            <Select
              options={temperatureOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10 cursor-pointer"
            />
          </div>
        </div>
     
       <div className='flex mt-5'>
             {/* Pulse Section */}
        <div className='w-[50%]'>
        <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Pulse</label>
            <div className="items-center text-xs flex gap-2">
              <div>73 taken on 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="Bpm" />
              <div>Bpm</div>
            </div>            <Select
              options={pulseOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10"
            />
          </div>
        </div>

        {/* Blood Glucose Section */}
        <div>
        <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Blood Glucose</label>
            <div className="items-center text-xs flex gap-2">
              <div>CBG : 122mg/dl taken on 28/02/2024</div>
            </div>
          </div>          <div className="flex space-x-2">
            <input type="text" className="w-full border rounded-md p-3" placeholder="mg/dl" />
            <div className="items-center flex gap-3">
              <div className='flex gap-1'>
              <UnitSelector  text="mg/dl"  width="45px" isSelected={selectedUnit === 'mg/dl'}   onClick={() => handleClickItem('mg/dl')} />  
              <UnitSelector  text="mmol/L"  width="55px" isSelected={selectedUnit === 'mmol/L'}   onClick={() => handleClickItem('mmol/L')} />    
              </div>
              <div className='flex gap-1'>
              <UnitSelector  text="Not-Fasting"  width="80px" isSelected={selectedUnit === 'Not-Fasting'}   onClick={() => handleClickItem('Not-Fasting')} />    
              <UnitSelector  text="Fasting"  width="60px" isSelected={selectedUnit === 'Fasting'}   onClick={() => handleClickItem('Fasting')} />    
            </div>
            </div>
          </div>
        </div>
        </div>
       {/*  o2 saturation and Repository */}
       <div className='flex mt-5 justify-between'>
             {/*  o2 saturation */}
        <div >
        <div className="flex gap-10">
            <label className="block text-sm text-gray-700">O2 Saturation</label>
            <div className="items-center text-xs flex gap-2">
              <div>100 taken on 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="flex items-center gap-2">
              <input type="text" className="w-full border rounded-lg p-3" placeholder="" />
              <div className='text-lg'> %</div>
            </div>            <Select
              options={heightOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10 cursor-pointer"
            />
          </div>
        </div>

        {/* Repository */}
        <div>
        <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Respiratory</label>
            <div className="items-center text-xs flex gap-2">
              <div>18 taken on 28/02/2024</div>
            </div>
          </div>        
          <div className="flex space-x-2">
            <div className="flex items-center gap-2">
              <input type="text" className=" border rounded-lg p-3" placeholder="" />
              <div className=''>Breaths & mins</div>
            </div>            <Select
              options={heightOptions}
              components={{ Option: customOption }}
              styles={customStyles}
              className="pr-10 cursor-pointer"
            />
          </div>
        </div>
       </div>
        {/* Additional fields as per design (Waist, Birth Weight, etc.) */}
        <div className='flex mt-3 '>
             {/*  V OD Right Eye */}
        <div className='w-[50%]' >
        <div className="flex gap-6">
            <label className="block text-sm text-gray-700">V OD Right Eye</label>
            <div className="items-center text-xs flex gap-2">
              <div>20/20 taken on 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2">
          <div className="flex items-center gap-2">
        <div className='text-xl'>20 /</div>
         <input type="text" className="border rounded-lg p-3 " placeholder="" />
        </div>
   
          </div>
        </div>

        {/* V OD Left Eye */}
        <div >
        <div className="flex gap-6">
            <label className="block text-sm text-gray-700">V OD Left Eye</label>
            <div className="items-center text-xs flex gap-2">
              <div>20/20 taken on 04/18/2024</div>
            </div>
          </div>
          <div className="flex space-x-2">
          <div className="flex items-center gap-2">
        <div className='text-xl'>20 /</div>
         <input type="text" className="border rounded-lg p-3 " placeholder="" />
        </div>
   
          </div>
        </div>
       </div>
       {/* below data */}
       <div className='flex mt-5 w-[80%] justify-between'>
             {/*  V OD Right Eye */}
         <div>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">V OS Left Eye</label>
          </div>
          <div className="flex space-x-2 mt-1">
           <div className="flex items-center gap-2">
              <input type="text" className=" border rounded-lg p-3" placeholder="cm" />
              <div>cm</div>
            </div>
          </div>
          </div>

        {/* V OD Left Eye */}
        <div>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Waist Circumference</label>
          </div>
          <div className="flex space-x-2 mt-1">
            <div className="flex items-center gap-2">
              <input type="text" className=" border rounded-lg p-3" placeholder="Inch" />
              <div>Inch</div>
            </div>
          </div>
          </div>
        {/* khgufydt */}
        <div>
          <div className="flex gap-10">
            <label className="block text-sm text-gray-700">Birth Weight</label>
          </div>
          <div className="flex space-x-2 mt-1">
           <div className="flex items-center gap-2">
               <input type="text" className=" border rounded-lg p-3" placeholder="Ibs" />
              <div>Ibs</div>
            </div>
          </div>
          </div>
       </div>

        <div>
       </div>
      </div>
    </div>
  );
};

export default Intake;
