import React from "react";

export const CardView = ({ icon, title }) => {
  return (
    <div className="w-[393px] h-[180px] flex flex-col items-start justify-start p-6 bg-white rounded-[15px] shadow-md border-t-2 border-[#1d2951]">
      <div className="flex items-center mb-2 mx-1.5">
        <img src={icon} alt={`${title} Icon`} className="mr-2" />
        <span className="text-sm text-[#1d2951] font-semibold">{title}</span>
      </div>
      <div className="w-full flex justify-center align-center">
        <ul className="w-[80%] space-y-2">
          <li className="flex justify-between items-center">
            <span className="text-sm text-[#1D2951]">Inhouse Labs- Female</span>
            <div className="w-2.5 h-2.5 bg-[#ed6c47] rounded-full"></div>
          </li>
          <hr className="border-gray-300 w-full" />
          <li className="flex justify-between items-center">
            <span className="text-sm text-[#1D2951]">CBC ,CMP , TSH</span>
            <div className="w-2.5 h-2.5 bg-[#ed6c47] rounded-full"></div>
          </li>
          <hr className="border-gray-300 w-full" />
          <li className="flex justify-between items-center">
            <span className="text-sm text-[#1D2951]">Thyroid - Free T4</span>
            <div className="w-2.5 h-2.5 bg-[#ed6c47] rounded-full"></div>
          </li>
        </ul>
      </div>
    </div>
  );
};
